import { Images } from '../Themes'
import { IOption } from '../types/index'

const options: IOption = {
    A: [
      { portrait: `${Images.avatar_boss_gym}`,icon:`${Images.house_01}`,img: `${Images.house_small}`, href: '/building/A', btnTitle: '分割店面', text: '6 坪的分割店面適合測試市場，也比較省成本，房租每月只要 25,000。', clickable: true,
        params: {
          work: ``, store:'0', employee:``, money:``,
          path: 'F',
          successCount: 0 ,
          skillA: 1,
          skillB: 1,
          skillC: 2,
          skillD: 1,
          skillE: 0
        }
      },
      { portrait: `${Images.avatar_boss_gym}`,icon:`${Images.house_02}`,img: `${Images.house_medium}`, href: '/building/A', btnTitle: '巷弄店面', text: '10 坪的巷弄店面大多了，房租 35,000 還可以接受。', clickable: true,
        params: {
          work: ``, store:'1', employee:``, money:``,
          path: 'F',
          successCount: 1,
          skillA: 1,
          skillB: -1,
          skillC: 2,
          skillD: 1,
          skillE: 0
        }
      },
      { portrait: `${Images.avatar_boss_gym}`,icon:`${Images.house_03}`,img: `${Images.house_big}`, href: '/building/A', btnTitle: '黃金店面', text: '雖然房租要價 80,000 ，但有兩層樓，且有 20 坪的空間可利用，又座落黃金地段一定會吸引很多人注意！', clickable: true,
        params: {
          work: ``, store:'2', employee:``, money:``,
          path: 'F',
          successCount: 1,
          skillA: 0,
          skillB: 0,
          skillC: -2,
          skillD: 0,
          skillE: 0
        }
      },
    ],
    B: [
      { portrait: `${Images.avatar_boss_cafe}`,icon:`${Images.house_01}`,img: `${Images.house_small}`, href: '/building/B', btnTitle: '分割店面', text: '6 坪的分割店面適合測試市場，也比較省成本，房租每月只要 25,000。', clickable: true,
        params: {
          work: ``, store:'0', employee:``, money:``,
          path: 'G',
          successCount: 1,
          skillA: 1,
          skillB: 0,
          skillC: 2, 
          skillD: 1,
          skillE: 0
        }
      },
      { portrait: `${Images.avatar_boss_cafe}`,icon:`${Images.house_02}`,img: `${Images.house_medium}`, href: '/building/B', btnTitle: '巷弄店面', text: '10 坪的巷弄店面大多了，房租 35,000 還可以接受。', clickable: true,
        params: {
          work: ``, store:'1', employee:``, money:``,
          path: 'G',
          successCount: 0,
          skillA: 1,
          skillB: -1,
          skillC: 2,
          skillD: 1,
          skillE: 0
        }
      },
      { portrait: `${Images.avatar_boss_cafe}`,icon:`${Images.house_03}`,img: `${Images.house_big}`, href: '/building/B', btnTitle: '燙金店面', text: '雖然房租要價 80,000 ，但有兩層樓，且有 20 坪的空間可利用，又座落黃金地段一定會吸引很多人注意！', clickable: true,
        params: {
          work: ``, store:'2', employee:``, money:``,
          path: 'G',
          successCount: 1,
          skillA: 0,
          skillB: 1,
          skillC: -2,
          skillD: 0, 
          skillE: 0
        }
      },
    ],
    C: [
      { portrait: `${Images.avatar_boss_clothes}`,icon:`${Images.house_01}`,img: `${Images.house_small}`, href: '/building/C', btnTitle: '分割店面', text: '6 坪的分割店面適合測試市場，也比較省成本，房租每月只要 25,000。', clickable: true,
        params: {
          work: ``, store:'0', employee:``, money:``,
          path: 'H',
          successCount: 1,
          skillA: 1,
          skillB: 0,
          skillC: 2,
          skillD: 1,
          skillE: 0
        }
      },
      { portrait: `${Images.avatar_boss_clothes}`,icon:`${Images.house_02}`,img: `${Images.house_medium}`, href: '/building/C', btnTitle: '巷弄店面', text: '10 坪的巷弄店面大多了，房租 35,000 還可以接受。', clickable: true,
        params: {
          work: ``, store:'1', employee:``, money:``,
          path: 'H',
          successCount: 1,
          skillA: 1,
          skillB: -1,
          skillC: 2,
          skillD: 1,
          skillE: 0
        }
      },
      { portrait: `${Images.avatar_boss_clothes}`,icon:`${Images.house_03}`,img: `${Images.house_big}`, href: '/building/C', btnTitle: '燙金店面', text: '雖然房租要價 80,000 ，但有兩層樓，且有 20 坪的空間可利用，又座落黃金地段一定會吸引很多人注意！', clickable: true,
        params: {
          work: ``, store:'2', employee:``, money:``,
          path: 'H',
          successCount: 0,
          skillA: 1,
          skillB: 1, 
          skillC: -2,
          skillD: 0,
          skillE: 0,
        }
      },
    ]
  }

export default options;