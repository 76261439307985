import React, {useState, useEffect}  from 'react'
import styled from 'styled-components'
import { IOptionTalkingText } from '../../types/index';
import { Metrics, Fonts, Styles } from '../../Themes'

const TextGroup = styled(Styles.OptionTextWrapper())`
`
const Card = styled(Styles.OptionTextCard())`
    .show{
        opacity: 1;
        transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
        .second{
            -ms-grid-row: 2
        }
    }
`
const Img = styled(Styles.OptionTextImg())`
    margin: 0 10px 0 32px ;
    opacity: 0;
    width: 52px;
    &:first-child{
        margin: 40px 10px 0 32px ;
    }
    @media (max-width: ${Metrics.tablet}){
        &:first-child{
            margin: 16px ;
        }
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
        &.second{
            margin: 0 0 40px 32px
        }
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
        &.second{
            margin: 16px;
        }
    }
`
const TextArea = styled(Styles.OptionTextTextArea())`
    margin-bottom: 10px;
    opacity: 0;
    &:last-child{
        margin: 0px 10px 0 32px ;
    }
    @media (max-width: ${Metrics.tablet}){
        margin: 0;
        padding-bottom: 0px;
        &:last-child{
            margin: 0 ;
        }
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
        margin: 0 16px 0 10px;
        &.second{
            margin: 0 16px 16px 10px;
        }
    }
`
const Text = styled(Fonts.optionText())`
`

const OptionTalkingText = (props: IOptionTalkingText) => {
    // const [ text, setText ] = useState('')
    // const [ text_2, setText_2 ] = useState('')
    // const [ typeDone, setTypeDone ] = useState(false)

    // useEffect(() => {
    //     setText(`${props?.text}`)
    //     if( typing ===`${props?.text}` ){
    //         setTimeout(() => {
    //             setText_2(`${props?.text_2}`)
    //             setTypeDone(true)
    //         ;}, 800)
    //     }else {
    //         setTypeDone(false)
    //         setText_2(``)
    //     }
    //     if(!`${props?.text_2}`){
    //         setTypeDone(false)
    //     }
    // })


    
    return (
        <TextGroup>
            <Card>
                {/* <Img className='show' src={props?.portrait} /> */}
                <TextArea className='show'>
                    {/* <Text>{typing}</Text> */}
                </TextArea>
                {/* <Img className={typeDone?'show':''} src={props?.portrait_2} />
                <TextArea className={typeDone?'show':''}> */}
                    {/* <Text>{typing_2}</Text> */}
                {/* </TextArea> */}
            </Card>
        </TextGroup>
    )
}

export default OptionTalkingText
