import React,{ useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom";
import styled from 'styled-components'

import MutiOption from "../components/MutiOption"
import Logo from "../components/Logo"
import MutiOptionWrapper from "../components/MutiOptionWrapperB"
import Skip from "../components/Button/Skip"

import { Styles } from '../Themes'
import { checkGameStatus } from "../utility"
import { IPath,  IProps } from '../types/index';

import options from '../Option/strengthOption'
import works from '../Option/All/works'
import stores from '../Option/All/stores'

import upgrade_01 from '../img/promote/upgrade_01.png'
import upgrade_02 from '../img/promote/upgrade_02.png'
import upgrade_03 from '../img/promote/upgrade_03.png'
import upgrade_04 from '../img/promote/upgrade_04.png'
import transparent from '../img/promote/transparent.png'

// let audio = new Audio(`${Audios.bo}`);

const Wrapper = styled(Styles.wrapper())``

const OptionDiv = styled.div`
  // width: 100%;
  margin: 0 auto;
  outline: none;
`

const Strength = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id || 'A';
  const path: IPath = { A: 'F', B: 'G', C: 'H' }
  const [strengthScore, setStrengthScore] = React.useState({
    number: 0,
    successCount: 0,
    skillA: 0,
    skillB: 0,
    skillC: 0,
    skillD: 0,
    skillE: 0,
  })
  const [mutiOptionImgs, setMutiOptionImgs] = useState([])
  const [portrait, setPortrait] = useState('')
  const [text, setText] = useState('有了第一次的行銷經驗，這次來試試看別的方法，看能不能更有成效。')
  const [img, setImg] = useState('')

  const imgList =[upgrade_01,upgrade_02,upgrade_03,upgrade_04]
  const classList = ['build','app','service','route']
  // const [playBgm, setPlayBgm] = useState(true)

  useEffect(() => {
    if(state){
      setImg(stores[state.store].img)
      setPortrait(works[state.work].portrait)
    }
    imgList.forEach((image) => {
      new Image().src = image
    });
  }, [])

  function Click(){
    setText('')
    setTimeout(() => {
      if(options[id][mutiOptionImgs[mutiOptionImgs.length-1]]!=undefined){
        setText(options[id][mutiOptionImgs[mutiOptionImgs.length-1]].text)
      }else{
        setText('有了第一次的行銷經驗，這次來試試看別的方法，看能不能更有成效。')
      }
    }, 100)
  }

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 1200);
  //   setPlayBgm(false)
  // }

  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }

  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-14)}/>
      <Skip params={options[`${id}Skip`][0].params} />
      <MutiOptionWrapper 
        title='請選擇資金運用項目'
        img={img}
        portrait={portrait}
        text={text}
        href={`/StrengthTransition/${id}`}
        clickable={strengthScore.number !== 0}
        mutiOptionImgA={imgList[0]}
        mutiOptionImgB={imgList[1]}
        mutiOptionImgC={imgList[2]}
        mutiOptionImgD={imgList[3]}
        mutiOptionImgE=''
        imgSelected={`${classList[mutiOptionImgs[0]]} ${classList[mutiOptionImgs[1]]}`}
        params={{
          work: '', store: '', employee: '', money: '',
          path: path[id],
          successCount: strengthScore.successCount,
          skillA: strengthScore.skillA,
          skillB: strengthScore.skillB,
          skillC: strengthScore.skillC,
          skillD: strengthScore.skillD,
          skillE: strengthScore.skillE,
          MutiOptionImgA: `${mutiOptionImgs[0]}`,
          MutiOptionImgB: `${mutiOptionImgs[0]}`,
          MutiOptionImgC: `${mutiOptionImgs[1]}`,
        }}
      >
        {options[id].map((i, index) => (
          <OptionDiv key={`style-${id}-${index}`} id={`${index}`} onClick={Click}>
            <MutiOption
              id={index}
              img={i.img}
              text=''
              btnTitle={i.btnTitle}
              icon={i.icon}
              number={strengthScore.number}
              limitNumber={2}
              mutiOptionImgs={mutiOptionImgs}
              params={i.params}
              click={setStrengthScore}
            />
          </OptionDiv>
        ))}
      </MutiOptionWrapper>
    </Wrapper>
  )
}

export default Strength
