import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Images, Colors, Metrics, Fonts } from '../Themes'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { ISummary } from "../types"
import { useCookies } from 'react-cookie';
// import useSound from 'use-sound';
// const clickSound = require('../audio/sound/click/coin.mp3');
import { FacebookShareButton } from "react-share";
import { getPersonility } from "../utility"

const Wrapper = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${Metrics.tablet}){
    width: 100vw;
    background: #FAFAFA;
    padding: 0px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    -ms-grid-column-align: end
  }
`

const Card = styled.div`
  background: #FFF;
  padding: 60px 38px;
  border-radius: 20px;
  width: auto;
  justify-self: center;
  align-self: center;
  max-width: 425px;
  margin-left: 20px;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${Metrics.tablet}){
    padding: inherit;
    width: 100vw;
    margin-left: 0;
    border-radius: 0px;
    max-width: inherit;
    background: #FAFAFA;
  }
`
const Title = styled(Fonts.h3())`
  font-weight: 600;
  @media (max-width: ${Metrics.tablet}){
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 32px;
    margin: 0 20px;
  }
`

const Text = styled(Fonts.p())`
  color: ${Colors.TitleBlack};
  margin: 12px 0;
  @media (max-width: ${Metrics.tablet}){
    margin: 8px 20px 0;
  }
`

const OptionGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(4,auto);
  padding: 0 0 20px;
  .restart{
    pointer-event: none;
    cursor: pointer
  }
  & ~ a{
    text-decoration: none;
  }
  @media (max-width: ${Metrics.tablet}){
    grid-gap: 4px;
    margin: 0 20px;
    margin-top: 120px;
  }
  @media (max-width: 425px){
    margin-top: 80px;
  }
  @media (max-width: 375px){
    margin-top: 80px;
  }
  @media (max-width: 320px){
    margin-top: 100px;
  }
  @media (max-width: 271px){
    margin-top: 55px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    display: flex;
    justify-content: center;
  }
`

const Option = styled.a`
  position: relative;
  display: grid;
  align-self: center;
  justify-self: center;
  width: 100%;
  text-decoration: none;
  max-width: 98px;
  &:hover{
    cursor: pointer;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-column: center;
  }
`

const OptionImg = styled.img`
  margin: 0 auto;
  border-radius: 12px;
  width: 100%;
  max-width: 82px;
  margin: 12px 0;
  justify-self: center;
  @media (max-width: ${Metrics.tablet}){
    max-width: 64px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    margin: 12px 8px;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    width: auto;
    margin: 12px 0;
    max-width: 65px;
    -ms-grid-column-align: center
  }
`

const Rader = styled.div`
  max-height: 280px;
  @media (max-width: 425px){
    max-height: 200px;
  }
  @media (max-width: 375px){
    max-height: 175px;
  }
  @media (max-width: 320px){
    max-height: 150px;
  }
`

const OptionP = styled(Fonts.p())`
  // z-index: 5;
  color: ${Colors.TitleBlack};
  margin: 0 auto;
  justify-self: center;
  align-self: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  pointer-event: none;
  @media (max-width: ${Metrics.tablet}){
    font-size: 14px;
    letter-spacing: 1.17px;
    line-height: 20px;
    max-width: 70px;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
`

const Button = styled(Fonts.button())`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 85%;
  cursor: pointer;
  color: #FFF ;
  background: ${Colors.BtnGreen};
  text-align: center;
  border-radius: 30px;
  padding: 16px 48px;
  font-size: 20px;
  border: none;
  transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
  outline: none;
  &:hover {
      transform: scale(0.95, 0.95);
	}
  @media (max-width: ${Metrics.tablet}){
    width: calc(100%-40px);
    margin-bottom: 6vh;
  }
`

const Summary = ({children, ...props}: ISummary) => {
  const history = useHistory();
  const state = history.location.state as any
  // const [play] = useSound(clickSound)
  const [ Return, setReturn ] = useState()
	const [ ignore, setIgnore ] = useState(false)
	const [ cookies, setCookie ] = useCookies(['returnURL']);

  const personility = getPersonility([
    { id: 'A', point: state?.skillA || 0 },
    { id: 'B', point: state?.skillB || 0 },
    { id: 'C', point: state?.skillC || 0 },
    { id: 'D', point: state?.skillD || 0 },
    { id: 'E', point: state?.skillE || 0 },
  ])

  function restart(){
    // play()
    history.push({pathname:'/'})
    window.location.href='/'
  }

	useEffect(() => {
		if(document.cookie.includes('returnURL')){
			setReturn(document.cookie.split('returnURL=')[1])
		}else{
			document.cookie="returnURL=https://ebank.firstbank.com.tw/FCBMEL/CSController?cmd=MEL000_1&s=S; path=/";
      setReturn(document.cookie.split('returnURL=')[1])
		}
	},[ignore])

  return(
    <Wrapper>
      <Card>
        <Title>{props.title}</Title>
        <Title>{props.title_2}</Title>
        <Text>{props.text}</Text>
        <Rader>
          {children}
        </Rader>
        <OptionGroup>
          <Option onClick={()=>{
            window.location.href=`${Return}`;
            setCookie('returnURL', '', { maxAge: 0 }) 
          }}>
            <OptionImg src={`${Images.etime}`} alt='' />
            <OptionP>微企e時貸</OptionP>
          </Option>
          <Option onClick={()=>{
              window.location.href=`${Return}#story`;
              setCookie('returnURL', '', { maxAge: 0 }) 
            }}>
            <OptionImg src={`${Images.work}`} alt=''/>
            <OptionP>創業故事</OptionP>
          </Option>
          <Option className='restart'>
              <OptionImg src={`${Images.refresh}`} alt='' onClick={() =>restart()} />
              <OptionP>再玩一次</OptionP>
          </Option>
          <FacebookShareButton url={`${window.location.origin}/result${props.id}`}>
          <Option href=''>
            <OptionImg src={`${Images.share}`} alt='' />
            <OptionP>分享結果</OptionP>
          </Option>
          </FacebookShareButton>
        </OptionGroup>
        {/* <Link to=''>
          <Button>專人與我聯繫</Button>
        </Link> */}
      </Card>
    </Wrapper>
  )
}

export default Summary
