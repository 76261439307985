import { ITransitions } from '../types/index';
import { Images } from '../Themes'

const options: ITransitions = {
    A: [{ 
      portrait: `${Images.avatar_boss_gym}`,
      img: `${Images.calendar_01}`, 
      href: '/money/A', 
      text: '半年過去，業務漸漸上了軌道，要讓更多人看見我們，需要尋找更多資金來源！',
    }],
    B: [{ 
      portrait: `${Images.avatar_boss_cafe}`,
      img: `${Images.calendar_01}`, 
      href: '/money/B', 
      text: '半年過去，業務漸漸上了軌道，要讓更多人看見我們，需要尋找更多資金來源！',
    }],
    C: [{ 
      portrait: `${Images.avatar_boss_clothes}`,
      img: `${Images.calendar_01}`,
      href: '/money/C', 
      text: '半年過去，業務漸漸上了軌道，要讓更多人看見我們，需要尋找更多資金來源！',
    }],
}

export default options;