import React from 'react'
import styled from 'styled-components'
import { IOptionText } from '../../types/index';
import { Colors, Metrics, Fonts, Styles } from '../../Themes'

const TextGroup = styled(Styles.OptionTextWrapper())`
`
const Card = styled(Styles.OptionTextCard())`
`
const Img = styled(Styles.OptionTextImg())`
    padding: 40px 32px;
`
const TextArea = styled(Styles.OptionTextTextArea())`
    width: auto;
    overflow: inherit;
    .Text{
        -ms-overflow-style: none;
        color: ${Colors.TitleBlack};
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 32px;
        margin: 0;
        text-align: left;
        margin-left: 15px;
    }
    @media (max-width: ${Metrics.tablet}){
        .Text{
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 24px;
            margin-left: inherit;
            width: 100%
        }
    }
`
const Text = styled(Fonts.optionText())`

`

const OptionText = (props: IOptionText) => {
    
  return (
    <TextGroup>
        <Card>
            {/* <Img src={props?.portrait} /> */}
            <TextArea>
                {/* <Text>{props?.text}</Text>  */}
            </TextArea>
        </Card>
    </TextGroup>
  )
}

export default OptionText
