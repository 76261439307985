import React, { useState, useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { Metrics, Styles } from '../Themes'
import OptionTalkingTextIE from "./Option/OptionTalkingTextIE"
import OptionTalkingTextNull from "./Option/OptionTalkingTextNull"
import OptionGroup from "./Option/OptionArea"
import { IOptionTwoWrapper } from '../types/index';
import { isIE } from 'react-device-detect';

const Wrapper = styled(Styles.OptionWrapper())``

const ImageArea = styled(Styles.OptionImageArea())``

const Img = styled(Styles.OptionImg())``

const OptionArea = styled(Styles.OptionAreaDiv())``


const OptionTwoWrapper = ({children, ...props}: IOptionTwoWrapper) => {
  const [ text, setText ] = useState('')
  const [ restart, setRestart ] = useState(true)
  
  useEffect(() => {
    if( !props.clickable ){
      setTimeout(() => {
        setText(`${props?.text}`)
      ;}, 1000)
      setRestart(true)
    }else{
        setRestart(false)
    }
  })

  function RestartF(){
    setRestart(true)
  }

  if(isIE){
    const OptionTalkingText = lazy(() => import("./Option/OptionTalkingTextIE"));
    return (
      <Wrapper>
        <ImageArea>
          <Img src={props.img} className={restart ? `${props.className}` : `${props.className_2}` } onLoad={()=> RestartF()} />
        </ImageArea>
        <OptionArea>
          <Suspense fallback={<OptionTalkingTextNull
              portrait={props?.portrait}
              text={props.clickable ? `${props?.text}` : `${text}`}
              portrait_2={props?.portrait_2}
              text_2={props?.text_2}
            />}>
            <OptionTalkingText
              portrait={props?.portrait}
              text={props.clickable ? `${props?.text}` : `${text}`}
              portrait_2={props?.portrait_2}
              text_2={props?.text_2}
            />
          </Suspense>
          <OptionGroup
            title={props?.title}
            href={props?.href}
            clickable={props?.clickable}
            params={props?.params}
          >
            {children}
          </OptionGroup>
        </OptionArea>
      </Wrapper>
    )
  }else{
    const OptionTalkingText = lazy(() => import("./Option/OptionTalkingText"));
    return (
      <Wrapper>
        <ImageArea>
          <Img src={props.img} className={restart ? `${props.className}` : `${props.className_2}` } onLoad={()=> RestartF()} />
        </ImageArea>
        <OptionArea>
          <Suspense fallback={<OptionTalkingTextNull
              portrait={props?.portrait}
              text={props.clickable ? `${props?.text}` : `${text}`}
              portrait_2={props?.portrait_2}
              text_2={props?.text_2}
            />}>
            <OptionTalkingText
              portrait={props?.portrait}
              text={props.clickable ? `${props?.text}` : `${text}`}
              portrait_2={props?.portrait_2}
              text_2={props?.text_2}
            />
          </Suspense>
          <OptionGroup
            title={props?.title}
            href={props?.href}
            clickable={props?.clickable}
            params={props?.params}
          >
            {children}
          </OptionGroup>
        </OptionArea>
      </Wrapper>
    )
  }
  
}

export default OptionTwoWrapper
