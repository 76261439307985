import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import { useHistory } from "react-router-dom";
import IndexPage from './containers/Index'
import Work from './containers/Work'
import Employee from './containers/Employee'
import WorkTransitions from './containers/WorkTransitions'
import EmployeeTransitions from './containers/EmployeeTransitions'
import Store from './containers/Store'
import Building from './containers/Building'
import Built from './containers/Built'
import Promotion from './containers/Promotion'
import PromotionTransitions from './containers/PromotionTransitions'
import NotFoundPage from './containers/404'
import StoreProvider from './store/index'
import HalfYear from './containers/HalfYear';
import Money from './containers/Money';
import MoneyTransitions from './containers/MoneyTransitions'
import Strength from './containers/Strength';
import StrengthTransitions from './containers/StrengthTransitions';
import OneYear from './containers/OneYear';
import Success from './containers/Success';
import Failed from './containers/Failed';
import Summary from './containers/Summary';
// import Loading from './components/Loading';
import { Audios } from './Themes'
import styled from 'styled-components'
import { isBrowser } from 'react-device-detect';

let refreshTimes = 're';
let audio = new Audio('')

if (isBrowser) {
  audio = new Audio(`${Audios.bgm}`);
}
const Wrapper = styled.div`
  scrollbarWidth: none;
  msOverflowStyle: none;
  margin: 0px auto;
  maxWidth: 1280px;
`

function App() {
  // const [playBgm, setPlayBgm] = useState(true)
  const [load, setLoading] = useState(true)
  const [ result, setResult ] = useState('')

  if(window.location.pathname === '/'){
    sessionStorage.clear();   
  }

  // if(playBgm){
  //   audio.load();
  //   audio.muted = false;
  //   audio.play();
  //   audio.loop = true;
  //   audio.volume = 0.3;
  //   window.addEventListener("click", () => {
  //     audio.muted= false;
  //     audio.play(); /* for mobile */
  //   }) 
  // }else{
  //   audio.muted = true;
  // }

  window.addEventListener('onload', function() { 
    setLoading(false)
  }, false);
  window.onload = function() {
    setLoading(false)
  };

  useEffect(() => {
  
    if (window.performance) {
      if (performance.navigation.type == 1) {
        window.location.href='/'
        sessionStorage.setItem('refreshed', refreshTimes);
        // sessionStorage.setItem('audioTime', `${audio.currentTime}`)
      }
    }
    
  }, [])

  return (
    <StoreProvider>
      <Router>
        <Wrapper
          className="switch-wrapper"
        >
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
          >
              <Route path="/" exact component={IndexPage} />
              <Route path="/work" component={Work} />
              <Route path="/WorkTransitions/:id" component={WorkTransitions} />
              <Route path="/store/:id" component={Store} />
              <Route path="/building/:id" component={Building} />
              <Route path="/built/:id" component={Built} />
              <Route path="/employee/:id" component={Employee} />
              <Route path="/EmployeeTransitions/:id" component={EmployeeTransitions} />
              <Route path="/promotion/:id" component={Promotion} />
              <Route path="/PromotionTransition/:id" component={PromotionTransitions} />
              <Route path="/halfyear/:id" component={HalfYear} />
              <Route path="/money/:id" component={Money} />
              <Route path="/MoneyTransition/:id" component={MoneyTransitions} />
              <Route path="/strength/:id" component={Strength} />
              <Route path="/StrengthTransition/:id" component={StrengthTransitions} />
              <Route path="/oneyear" component={OneYear} />
              <Route path="/success" component={Success} />
              <Route path="/failed" component={Failed} />
              <Route path="/summary/:id" component={Summary} />
              <Route path="*" component={NotFoundPage} />
          </AnimatedSwitch>
        </Wrapper>
      </Router>
    </StoreProvider>
  );
}

export default App;