import React,{ useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import styled from 'styled-components'

import MutiOption from '../components/MutiOption'
import Logo from "../components/Logo"
import MutiOptionWrapper from "../components/MutiOptionWrapper"
import Skip from "../components/Button/Skip"

import { Styles } from '../Themes'
import { IProps, IPath } from '../types/index'
import { checkGameStatus } from '../utility'

import options from '../Option/promotionOptions'
import works from '../Option/All/works'
import stores from '../Option/All/stores'

import promote_01 from '../img/promote/promote_01.png'
import promote_02 from '../img/promote/promote_02.png'
import promote_03 from '../img/promote/promote_03.png'
import promote_04 from '../img/promote/promote_04.png'
import promote_05 from '../img/promote/promote_05.png'

// let audio = new Audio(`${Audios.bo}`);

const Wrapper = styled(Styles.wrapper())``

const OptionDiv = styled.div`
  margin: 0 auto;
  outline: none;
`

const Promotion = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  const path: IPath = {A: 'F', B: 'G', C:'H'}
  const [promotionScore, setPromotionScore] = React.useState({
    number: 0,
    successCount: 0,
    skillA: 0,
    skillB: 0,
    skillC: 0,
    skillD: 0,
    skillE: 0,
  })
  const [mutiOptionImgs, setMutiOptionImgs] = useState([])
  const [portrait, setPortrait] = useState('')
  const [text, setText] = useState('公司開始營運了！ 如何提高能見度也是要煩惱的事情，來試試看能不能透過行銷活動導入更多客源，帶來更多的商機！')
  const [img, setImg] = useState('')

  const imgList = [promote_01,promote_02,promote_03,promote_04,promote_05]
  const classList =['youtube','chat','sale','idol','fb']
  // const [playBgm, setPlayBgm] = useState(true)

  useEffect(() => {
    if(state){
      setImg(stores[state.store].img)
      setPortrait(works[state.work].portrait)
    }
    imgList.forEach((image) => {
      new Image().src = image
    });

  }, [])
  
  function Click(){
    setText('')
    setPortrait(works[state.work].portrait)
    if(options[id][mutiOptionImgs[mutiOptionImgs.length-1]]!=undefined){
      setText(options[id][mutiOptionImgs[mutiOptionImgs.length-1]].text)
    }else{
      setText('公司開始營運了！ 如何提高能見度也是要煩惱的事情，來試試看能不能透過行銷活動導入更多客源，帶來更多的商機！')
    }
  }

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 1000);
  //   setPlayBgm(false)
  // }
  
  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-9)}/>
      <Skip params={options[`${id}Skip`][0].params} />
      <MutiOptionWrapper 
        title='請選擇行銷方式（可複選）'
        img={img}
        portrait={portrait}
        text={text}
        href={`/PromotionTransition/${id}`}
        clickable={promotionScore.number !== 0}
        mutiOptionImgA={imgList[0]}
        mutiOptionImgB={imgList[1]}
        mutiOptionImgC={imgList[2]}
        mutiOptionImgD={imgList[3]}
        mutiOptionImgE={imgList[4]}
        imgSelected={`${classList[mutiOptionImgs[0]]} ${classList[mutiOptionImgs[1]]} ${classList[mutiOptionImgs[2]]}`}
        params={{
          work: '', store: '', employee: '', money: '',
          path: path[id],
          successCount: promotionScore.successCount,
          skillA: promotionScore.skillA,
          skillB: promotionScore.skillB,
          skillC: promotionScore.skillC,
          skillD: promotionScore.skillD,
          skillE: promotionScore.skillE,
          MutiOptionImgA: `${mutiOptionImgs[0]}`,
          MutiOptionImgB: `${mutiOptionImgs[1]}`,
          MutiOptionImgC: `${mutiOptionImgs[2]}`,
        }}
      >
        {options[id].map((i, index) => (
          <OptionDiv key={`style-${id}-${index}`} id={`${index}`} onClick={Click}>
            <MutiOption
              id={index}
              img={i.img}
              text=''
              btnTitle={i.btnTitle}
              icon={i.icon}
              number={promotionScore.number}
              limitNumber={3}
              mutiOptionImgs={mutiOptionImgs}
              params={i.params}
              click={setPromotionScore}
            />
          </OptionDiv>
        ))}
      </MutiOptionWrapper>
    </Wrapper>
  )
}

export default Promotion
