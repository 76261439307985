import React from 'react'
import styled from 'styled-components'
import { IOptionButton } from '../types/index';
import { Colors, Metrics, Fonts, Styles } from '../Themes'
import ic_check from '../img/ic_check.svg'
// import useSound from 'use-sound';
// const clickSound = require('../audio/sound/click/coin.mp3');

const OptionDiv = styled(Styles.OptionDiv())`

`
const Input = styled.input`
  display: none;
  &:checked+label img{
    border: 4px solid ${Colors.BtnGreen} ;
    transition: .2s;
  }
  &:checked+label > .check{
    border: 0px;
    opacity: 1;
  }
  &+label:hover{
    cursor: pointer;
  }
`
const ImgArea= styled.label`
  display: grid;
  width: 100%;
`
const Img = styled.img`
  background: #ddd;
  margin: 0 auto;
  border: 4px solid #FFF;
  border-radius: 12px;
  width: 100%;
  max-width: 80px;
  @media (max-width: ${Metrics.tablet}){
    max-width: 65px;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    width: auto;
  }
`
const Check = styled(Styles.Check())`
  right: 0;
  top: -8px;
  @media (max-width: ${Metrics.tablet}){
    right: -8px;
  }
`

const OptionP = styled(Fonts.p())`
  pointer-events: none;
  text-align: center;
`

const Option = (props: IOptionButton) => {
  // const [play] = useSound(clickSound)
  return (
    <OptionDiv >
      <Input
        type='radio'
        name='work'
        id={props?.id}
        className='hidden'
      />
      <ImgArea htmlFor={props?.for} >
        <Img src={props.img} />
        <OptionP >{props.btnTitle}</OptionP>
        <Check src={ic_check} className='check' />
      </ImgArea>
    </OptionDiv>
  )
}

export default Option
