import React from 'react'
import styled from 'styled-components'
import { Colors, Metrics, Styles, Images, Fonts } from '../Themes'
// import Button from './Button'
import { useHistory } from "react-router-dom";
import { ILargeTitleBtnProps } from '../types/index';

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0-iOS 11.1 */
  padding-bottom: env(safe-area-inset-bottom); /*iOS 11.2 */
  @media (max-width: ${Metrics.tablet}){
    height: 90vh;
  }
`

const TitleArea = styled.div`
  position: relative;
  width: 100%;
  height: 20vh;
  top: 6%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`
const SectionTitle = styled(Fonts.h1())`
  z-index: 5;
  color: ${Colors.TitleBlack};
  margin: 0 15px;
`
const SectionText = styled(Fonts.p())`
  z-index: 5;
  color: ${Colors.SubTitleGray};
  margin: 4px 15px 20px;
`

const TitleLine = styled.div`
  height: 4px;
  width: 100px;
  background-color: #5FC68B;
  margin: 0 auto;
`

const Img = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 75%;
  height: auto;
  margin-top: 60px;
  margin-left: -10px;
  transition: 1s;
  z-index: -1;
  @media (max-width: ${Metrics.tablet}){
    min-width: 500px;
  }
  @media (max-width: 320px){
    min-width: 620px;
  }
`

const Logo = styled.img`
  position: absolute;
  left: 50%;
  top: 22%;
  transform: translate(-50%,-50%);
  width: 40%;
  margin-top: 70px;
  height: 15%;
  max-width: 300px;
  @media (max-width: ${Metrics.tablet}){
    margin-top: 50px
  }
`

const Button = styled(Styles.button())`
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  color: #FFF;
  background: ${Colors.BtnGreen};
  height: 60px;
  width: 95%;
  max-width: 375px;
  padding: ${Metrics.px3} ${Metrics.px8};
  font-size: 20px;
  &:hover {
    transform: translateX(-50%) scale(0.95, 0.95);
  }
  @media (max-width: ${Metrics.tablet}){
    bottom: 10vh
  }
`

const LargeTitleBtn = (props: ILargeTitleBtnProps) => {
  const history = useHistory();
  return (
    <Wrapper>
      <TitleArea>
        <SectionTitle>{props?.title}</SectionTitle>
        <SectionText>{props?.text}</SectionText>
        <TitleLine />
      </TitleArea><Logo src={`${Images.logo}`} />
      <Img src={`${Images.home}`} />
      
      <div
        onClick={() => history.location.state 
          ? history.push({ pathname: `${props.href}`, state: history.location.state }) 
          : history.push({ pathname: `${props.href}`})}
        style={{ margin: `0 auto` }}
      >
        <Button>開始遊戲</Button>
      </div>
    </Wrapper>
  )
}

export default LargeTitleBtn
