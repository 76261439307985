import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Images, Metrics } from '../Themes'
import { useHistory } from "react-router-dom";
import { ILogo } from '../types/index';
import { useCookies } from 'react-cookie';

const LogoArea = styled.div`
	height: 0px;
	z-index: 1000;
	position: relative;
	@media (max-width: ${Metrics.tablet}){
		//background: #FAFAFA;
		width: 100%;
		position: fixed;
		height: 55px;
		z-index: 100;
		top: 0;
	}
`
const Img = styled.img`
	position: relative;
	top: 20px;
	left: 16px;
	cursor: pointer;
	transition: .6s;
	@media (max-width: ${Metrics.tablet}){
		position: fixed;
		top: 20px;
		left: 20px;
		width: 100px;
		min-height: 25px;
		max-height: 35px;
    }
`

const Logo = ( props: ILogo ) => {
	const history = useHistory();
	const [ Return, setReturn ] = useState()
	const [ ignore, setIgnore ] = useState(false)
	const [ cookies, setCookie ] = useCookies(['returnURL']);

	useEffect(() => {
		if(document.cookie.includes('returnURL')){
			setReturn(document.cookie.split('returnURL=')[1])
		}else{
			document.cookie="returnURL=https://ebank.firstbank.com.tw/FCBMEL/CSController?cmd=MEL000_1&s=S; path=/";
		}
	},[ignore])
    
	return(
		<LogoArea onClick={()=>{
			window.location.href=`${Return}`;
			setCookie('returnURL', '', { maxAge: 0 }) 
		}}>
			<Img src={`${Images.logo}`} />
		</LogoArea>
	)
}

export default Logo