import React, { useState, useEffect, Suspense, lazy } from 'react'
import styled, { keyframes } from 'styled-components'
import { Styles } from '../Themes'
import { isIE } from 'react-device-detect';

import OptionTextIE from "./Option/OptionTextIE"
import OptionTextN from "./Option/OptionTextNull"

import OptionGroup from "./Option/OptionArea"
import { IOptionWrapper } from '../types/index';

const bounceInCustom = keyframes`
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
      -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
      animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
      opacity: 0;
      -webkit-transform: scale3d(.9, .9, .9);
      transform: scale3d(.9, .9, .9)
  }

  20% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1)
  }

  40% {
      -webkit-transform: scale3d(.97, .97, .97);
      transform: scale3d(.97, .97, .97)
  }

  60% {
      opacity: 1;
      -webkit-transform: scale3d(1.01, 1.01, 1.01);
      transform: scale3d(1.01, 1.01, 1.01)
  }

  80% {
      -webkit-transform: scale3d(.99, .99, .99);
      transform: scale3d(.99, .99, .99)
  }

  to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
  }
`;

const Wrapper = styled(Styles.OptionWrapper())``

const ImageArea = styled(Styles.OptionImageArea())`
  .animate__bounceIn {
    -webkit-animation-name: ${bounceInCustom};
    animation-name: ${bounceInCustom}
  }
`

const Img = styled(Styles.OptionImg())``

const OptionArea = styled(Styles.OptionAreaDiv())``


const OptionWrapper = ({children, ...props}: IOptionWrapper) => {
  const [ text, setText ] = useState('')
  const [ restart, setRestart ] = useState(true)

  useEffect(() => {
    if( !props.clickable ){
      setTimeout(() => {
        setText(`${props?.text}`)
      }, 1500)
      setRestart(true)
    }else{
        setRestart(false)
    }

  })
  
  function RestartF(){
    setRestart(true)
  }

  if(isIE){
      const OptionText = lazy(() => import("./Option/OptionTextIE"));
      return (
        <Wrapper>
          <ImageArea>
            <Img src={props.img} loading='lazy' className={restart ? `${props.className}` : `${props.className_2}` } onLoad={()=> RestartF()} />
          </ImageArea>
          <OptionArea>
            <Suspense fallback={<OptionTextN
                portrait={props?.portrait}
                text={ props.clickable ? `${props?.text}` : `${text}` }
              />}>
              <OptionText
                portrait={props?.portrait}
                text={ props.clickable ? `${props?.text}` : `${text}` }
              />
          </Suspense>
            <OptionGroup
              title={props?.title}
              href={props?.href}
              clickable={props?.clickable}
              params={props?.params}
            >
              {children}
            </OptionGroup>
          </OptionArea>
        </Wrapper>
      )
  }else{
      const OptionText= lazy(() => import("./Option/OptionText"));
      return (
        <Wrapper>
          <ImageArea>
            <Img src={props.img} loading='lazy' className={restart ? `${props.className}` : `${props.className_2}` } onLoad={()=> RestartF()} />
          </ImageArea>
          <OptionArea>
            <Suspense fallback={<OptionTextN
                portrait={props?.portrait}
                text={ props.clickable ? `${props?.text}` : `${text}` }
              />}>
              <OptionText
                portrait={props?.portrait}
                text={ props.clickable ? `${props?.text}` : `${text}` }
              />
               </Suspense>
            <OptionGroup
              title={props?.title}
              href={props?.href}
              clickable={props?.clickable}
              params={props?.params}
            >
              {children}
            </OptionGroup>
          </OptionArea>
        </Wrapper>
      )
    }
  
}

export default OptionWrapper
