import { Fonts, Metrics } from '../Themes'
import styled, { keyframes } from 'styled-components'

const Styles = {
  wrapper: () => styled.section`
    width: 100%;
    height: auto;
    margin: 0 auto;
    min-height: 400px;
    max-width: 1280px;
    ::-webkit-scrollbar {
      display: none;
      width: 0px;
      background: transparent;
    }
  `,
  button: () => styled(Fonts.button())`
    cursor: pointer;
    border-radius: 30px;
    transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    outline: none;
    border: none;
    &:hover {
    transform: scale(0.95, 0.95);
	  }
  `,
  //All
  OptionWrapper: () => styled.div`
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      height: 100vh;
    }
    @media (max-width: ${Metrics.tablet}){
      height: 85vh;
      overflow-x: hidden;
      margin-top: 5vh
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      height: 95vh;
    }
  `,
  //Option
  OptionImageArea: () => styled.div`
    height: 50vh;
    justify-self: center;
    display: flex;
    width: 100%;
    align-items: flex-end;
    position: relative;
    margin-top: 60px;
    pointer-events: none;
    @media (max-width: ${Metrics.tablet}){
      height: 30vh;
      margin-top: 10px;
    }
  `,
  OptionImg: () => styled.img`
    width: auto;
    max-width: 525px;
    height: 100%;
    max-height: 421px;
    margin: 0 auto;
    pointer-events: none;
    @media (max-width: ${Metrics.tablet}){
      width: auto;
      position: relative;
      height: 100%;
      max-height: 282px;
    }
    
  `,
  OptionAreaDiv: () => styled.div`
    align-self: start;
    display: grid;
    grid-template-columns: 60% 40%;
    position: relative;
    width: 100%;
    height: 40vh;
    @media (max-width: ${Metrics.tablet}){
      height: auto;
      grid-template-columns: 100%;
      
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      display: -ms-grid;
      -ms-grid-columns: 1.5fr 1fr;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
        -ms-grid-columns: 100%;
    }
  `,
  //MutiOption
  MutiOptionImages: () => styled.div`
    width: 100%;
    height: 50vh;
    justify-self: center;
    align-items: flex-end;
    display: flex;
    position: relative;
    bottom: 25px;
    pointer-events: none;
    .animate__pulse.animate__animated{
      animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
    }
    @media (max-width: ${Metrics.tablet}){
      height: 30vh;
      margin-top: 20px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      height: 60vh;
      bottom: 15px;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      height: 30vh;
    }
  `,
  MutiBg: () => styled.img`
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: auto;
    max-height: 345px;
    @media (max-width: ${Metrics.tablet}){
      display: none;
    }
  `,
  MutiImg: () => styled.img`
    width: auto;
    max-width: 525px;
    height: 100%;
    max-height: 421px;
    margin: 0 auto;
    @media (max-width: ${Metrics.tablet}){
      width: auto;
      position: relative;
      height: 100%;
      max-height: 282px;
    }
    @media (max-height: 960px){
      height: 100%;
    }
  `,
  MutiEmployee: () => styled.img`
    height: 50%;
    position: absolute;
    max-height: 226px;
    transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    @media (max-width: ${Metrics.tablet}){
      max-height: 180px;
      width: auto;
    }
  `,
  MutiImgGroup: () => styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    @media (max-width: ${Metrics.tablet}){
      max-height: 500px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      top: 0;
      left: 0;
    }
  `,
  MutiOptionImg: () => styled.img`
    position: absolute;
    z-index: 1;
    opacity: 0;
    max-width: 100px;
    @media (max-width: ${Metrics.tablet}){
      width: 60px;
    }
    @media (max-height: 800px){
      max-width: 100px;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      width: auto;
      max-width: 60px;
    }
  `,
  MutiOptionImgTrans: () => styled.img`
    position: absolute;
    z-index: 1;
    opacity: 0;
    &.youtube{
      bottom: 60%;
      left: 60%;
      opacity: 1;
    }
    &.chat{
      bottom: 20%;
      left: 70%;
      opacity: 1;
    }
    &.sale{
      bottom: 55%;
      left: 25%;
      opacity: 1;
    }
    &.idol{
      bottom: 50%;
      left: 40%;
      opacity: 1;
    }
    &.fb{
      bottom: 20%;
      left: 40%;
      opacity: 1;
    }
    &.build{
      bottom: 5%;
      left: 24%;
      opacity: 1;
    }
    &.app{
      bottom: 5%;
      left: 70%;
      opacity: 1;
    }
    &.service{
      bottom: 55%;
      left: 35%;
      opacity: 1;
    }
    &.route{
      bottom: 54%;
      left: 60%;
      opacity: 1;
    }
    @media (max-width: ${Metrics.tablet}){
      width: 60px;
      &.youtube{
        bottom: 60%;
        left: 55%;
      }
      &.chat{
        bottom: 20%;
        left: 75%;
      }
      &.sale{
        bottom: 50%;
        left: 12%;
      }
      &.idol{
        bottom: 25%;
        left: 38%;
      }
      &.fb{
        bottom: 0%;
        left: 10%;
      }
      &.build{
        bottom: 5%;
        left: 24%;
      }
      &.app{
        bottom: 0%;
        left: 55%;
      }
      &.service{
        bottom: 50%;
        left: 25%;
      }
      &.route{
        bottom: 54%;
        left: 65%;
      }
    }
    @media (max-height: 800px){
      max-width: 100px;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      width: auto;
      max-width: 60px;
    }
  `,
  MutiOptionArea: () => styled.div`
    align-self: start;
    display: grid;
    grid-template-columns: 55% 45%;
    position: relative;
    width: 100%;
    height: 40vh;
    @media (max-width: ${Metrics.tablet}){
      height: auto;
      grid-template-columns: 100%;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      display: -ms-grid;
      -ms-grid-columns: 60% 40%;
    }
  `,
  //Transition
  TransitionWrapper: () => styled.div`
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  TransitionImgArea: () => styled.div`
    width: auto;
    justify-self: center;
    display: flex;
    width: 100%;
    align-items: flex-end;
    position: relative;
    .animate__pulse.animate__animated,
    .animate__flash.animate__animated{
      animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
    }
    @media (max-width: ${Metrics.tablet}){
      height: 40vh;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      height: 60vh;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      height: 40vh;
    }
  `,
  TransitionBg: () => styled.img`
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: auto;
    max-height: 421px;
    @media (max-width: ${Metrics.tablet}){
      height: 100%;
      width: auto;
      max-height: 250px;
      left: 50%;
    }
  `,
  TransitionImg: () => styled.img`
    width: auto;
    max-width: 525px;
    height: 100%;
    max-height: 421px;
    margin: 0 auto;
    @media (max-width: ${Metrics.tablet}){
      width: auto;
      height: 80% !important;
      max-height: 282px;
      position: relative;
    }
  `,
  TransitionOptionArea: () => styled.div`
    align-self: start;
    display: grid;
    grid-template-columns: 80% 20%;
    position: relative;
    width: 100%;
    max-height: 250px;
    @media (max-width: ${Metrics.tablet}){
      grid-template-columns: repeat(1,auto);
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      display: -ms-grid;
      -ms-grid-columns: 80% 20%;
      .next{
          -ms-grid-column: 2;
          -ms-grid-row-align: end;
      }
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      -ms-grid-columns: 100%;
      .next{
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
    }
  `,
  //Option
  OptionDiv: () => styled.div`
    position: relative;
    display: grid;
    justify-self: center;
    align-self: center;
    outline: none;
    @media (max-width: ${Metrics.tablet}){
      max-width: 76px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      display: grid -ms-grid;
      -ms-grid-row-align: center;
      -ms-grid-column-align: center;
    }
    
  `,
  Check: () => styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    opacity: 0;
  `,
  OptionGroupWrapper: () => styled.div`
    height: 250px;
    @media (max-width: ${Metrics.tablet}){
        height: 100%; 
        align-content: end;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      -ms-grid-column: 2;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }
  `,
  OptionGroupCard: () => styled.div`
    background-color: #FFFFFF;
    border-radius: 20px;
    height: 90%;
    margin-right: 12px;
    @media (max-width: ${Metrics.tablet}){
      height: 100%;
      margin: 0;
    }
  `,
  OptionGroupTitleArea: () => styled.div`
    display: grid;
    grid-template-columns: repeat(2,auto);
    width: calc(100%-32px);
    justify-items: left;
    padding: 16px 16px 0;
    @media (max-width: ${Metrics.tablet}){
        padding: 10px 16px 0;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      display: flex;
      justify-content: space-between
    }
  `,
  OptionGroupTitle: () => styled(Fonts.h3())`
    align-self: center;
    font-weight: 500;
  `,
  OptionGroupArea: () => styled.div`
    display: block; 
    padding: 10px 16px;
    overflow: hidden;
    .slick-slide{
        max-width: 110px;
        outline: none;
    }
    .slick-track{
        padding: 10px 0;
    }
    @media (max-width: ${Metrics.tablet}){
        padding-bottom: 20px;
        padding: 0 ;
        max-width: 100vw;
        .slick-track{
            margin: 0 10px;
        }
    }
  `,
  OptionTextWrapper: () => styled.div`
    height: 250px;
    @media (max-width: ${Metrics.tablet}){
        height: 100%;
        min-height: 144px;
        max-height: 144px;
    }

  `,
  OptionTextCard: () => styled.div`
    background-color: #FFFFFF;
    border-radius: 20px;
    position: relative;
    display: grid;
    grid-template-columns: 60px auto;
    justify-items: start;
    align-items: start;
    height: 90%;
    margin: 0 12px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
      width: 0px;
      background: transparent;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      display: -ms-grid;
      -ms-grid-columns: 40px auto;
      -ms-grid-row-align: start;
      -ms-grid-column-align: start;
    }
  `,
  OptionTextImg: () => styled.img`
    border-radius: 100%;
    @media (max-width: ${Metrics.tablet}){
      margin: 16px;
      width: 30px;
      padding: inherit
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      border-radius: 50px;
      max-height: 50px;
      width: 50px;
      padding: 0;
      margin: 40px 0 40px 32px;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      margin: 16px;
      width: auto;
      min-width: 30px;
      height: 30px;
    }
  `,
  OptionTextTextArea: () => styled.div`
    margin: 40px 32px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: ${Metrics.tablet}){
      padding: 16px 16px;
      padding-left: 0px;
      margin: 0;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
      margin: 40px 32px 40px 50px;
      -ms-grid-column: 2;
    }
    @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      margin: 0 16px 0 10px;

    }
  `
}
export default Styles