import React from 'react'
import styled from 'styled-components'
import { Colors, Metrics, Fonts } from '../../Themes'
import { IButon } from '../../types/index';

const SectionBtn = styled(Fonts.button())`
  color: #000 ;
  background: #E0E0E0;
  text-align: center;
  border-radius: 22px;
  padding: 5px 18px;
  font-size: 20px;
  border: none;
  transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
  outline: none;
  &.active{
    color: #FFF ;
    background: ${Colors.BtnGreen};
    cursor: pointer;
  }
  &:hover {
    transform: scale(0.95, 0.95);
  }
  @media (max-width: ${Metrics.tablet}){
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 22px;
    padding: 4px 15px;
  }
`

const Button = (props: IButon) => (
	<SectionBtn className={props.clickable ? `active` : ``}>{props.btnTitle}</SectionBtn>
)

export default Button