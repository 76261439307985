import React, { useState, useEffect } from 'react'
import { Images, Styles, Audios } from '../Themes'
import styled from 'styled-components'
import Option from "../components/Option"
import Logo from "../components/Logo"
import OptionWrapper from "../components/OptionWrapper"
import { IProps } from "../types"
import { IOptionItem } from '../types/index'
import logo_gym from '../img/main/logo_gym.png'
import logo_cafe from '../img/main/logo_cafe.png'
import logo_clothes from '../img/main/logo_clothes.png'
import { useHistory } from "react-router-dom";
// let audio = new Audio(`${Audios.bo}`);

const Wrapper = styled(Styles.wrapper())``

const OptionDiv = styled.div`
  margin: 0 auto;
  outline: none;
`

const options: Array<IOptionItem> = [
  {
    portrait: ``,icon:`${Images.gym}`,img: `${Images.logo_gym}`, href: '/workTransitions/A', btnTitle: '健身房', text: '投入的資金較高，但是後期收入也蠻可觀的。', clickable: true,
    params: {
      work: '0', store:``, employee:``, money:``, 
      path: 'F',
      successCount: 0,
      skillA: 6,
      skillB: 6,
      skillC: 6,
      skillD: 6,
      skillE: 6,
    }
  },
  {
    portrait: ``,icon:`${Images.cafe}`,img: `${Images.logo_cafe}`, href: '/workTransitions/B', btnTitle: '咖啡廳', text: '喜歡喝咖啡的我，一定可以抓到市場的口味。', clickable: true,
    params: {
      work: '1', store:``, employee:``, money:``,
      path: 'G',
      successCount: 0,
      skillA: 6,
      skillB: 6,
      skillC: 6,
      skillD: 6,
      skillE: 6,
    }
  },
  {
    portrait: ``,icon:`${Images.clothes}`, img: `${Images.logo_clothes}`, href: '/workTransitions/C', btnTitle: '服飾店', text: '不管男女老少，都有對服飾的需求，感覺能創造穩定的收入。', clickable: true,
    params: {
      work: '2', store:``, employee:``, money:``,
      path: 'H',
      successCount: 0,
      skillA: 6,
      skillB: 6,
      skillC: 6,
      skillD: 6,
      skillE: 6,
    }
  },
]

const WorksPage = (props: IProps) => {
  const [selected, setSelected] = useState('')
  const [portrait, setPortrait] = useState('')
  const [text, setText] = useState('')
  const [img, setImg] = useState('')
  const [href, setHref] = useState('')
  const [params, setParams] = useState()
  const [clickable, setClickable] = useState(false)
  const imgList = [ logo_gym,logo_cafe,logo_clothes ]
  // const [playBgm, setPlayBgm] = useState(true)
  const history = useHistory();
  useEffect(() => {
   
    setImg(`${Images.boss_empty}`)
    setPortrait(`${Images.avatar_boss_empty}`)
    setText('工作多年累積了一些人脈與資金，是時候開創自己的事業了。健身房、手沖咖啡店、服飾店都滿有潛力的，要選哪一個呢？')
    setHref('')

    imgList.forEach((image) => {
      new Image().src = image
    });
    
    for (let i = 0; i < 3 ; i++) {
      if( selected === `${i}`){
        setImg(imgList[i])
        setText(options[i].text)
        setParams(options[i].params)
        setHref(options[i].href)
        setClickable(options[i].clickable)
      }
    }
  
  })

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.autoplay = true;
  //     audio.play();
  //     /* for mobile */
  //     window.addEventListener("click", () => {
  //       audio.muted= false;
  //     })
  //   }, 1000);
  //   setPlayBgm(false)
  // }

  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-2)} />
      <OptionWrapper 
        className='animate__fadeIn animate__animated animate__delay-1s animate__faster'
        className_2='animate__bounceIn animate__animated'
        title='請選擇一個您想從事的職業'
        img={img}
        portrait={`${Images.avatar_boss_empty}`}
        text={ text }
        href={href}
        clickable={clickable}
        params={params}
      >
        {options.map((i, index) => (
          <OptionDiv key={`work-${index}`} id={`${index}`} onClick={() =>setSelected(`${index}`)}>
            <Option
              // clickable={i.clickable}
              // key={`work-${index}`}
              img={i.icon}
              // href={i.href}
              btnTitle={i.btnTitle}
              text={i.text}
              // params={i.params}
              id={`work_${index}`}
              for={`work_${index}`}
            />
          </OptionDiv>
        ))}
      </OptionWrapper>
    </Wrapper>
  )
}

export default WorksPage
