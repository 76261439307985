import React from 'react'
import styled from 'styled-components'
import { Metrics, Fonts, Styles } from '../Themes'
import { IMutiOptionProps } from '../types/index';
import ic_check from '../img/ic_check.svg'
// import useSound from 'use-sound';
// const clickSound = require('../audio/sound/click/coin.mp3');
// const alertSound = require('../audio/sound/alert/alert_2.mp3');

const OptionGroup = styled(Styles.OptionDiv())`
  max-width: 90px;
  
`
const Img = styled.img`
  margin: 0 auto;
  border: 4px solid #FFF;
  border-radius: 12px;
  width: 100%;
  max-width: 80px;
  &.active{
    border: 4px solid #5FC68B;
  } 
  &.active ~ .check {
    opacity: 1;
  }
  @media (max-width: ${Metrics.tablet}){
    max-width: 65px;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    width: auto;
  }
`
const MutiButton = styled.button`
  outline: 0px;
  margin: 0 auto;
  border: 0px;
  background: #FFF;
  padding: 0;
  width: 100%;
  &:hover{
    cursor: pointer;
  }
  @media (max-width: ${Metrics.tablet}){
    background: transparent;
  }
`
const Check = styled(Styles.Check())`
  right: -8px;
  top: -8px;
  @media (max-width: ${Metrics.tablet}){
    right: -8px;
  }
`

const OptionP = styled(Fonts.label())`
`

const MutiOption = (props: IMutiOptionProps) => {
  const [isSelected, setSelect] = React.useState(false);
  // const [play] = useSound(clickSound)
  // const [play_2] = useSound(alertSound)

  return (
    <OptionGroup>
      <MutiButton
        onClick={() => {
          if (props.number >= props.limitNumber && !isSelected) {
            // play_2()
            alert(`最多不能選超過${props.limitNumber}個選項`)
          } else {
            if (isSelected) {
              props?.click?.(
                (prevState) => ({
                  number: prevState.number - 1,
                  successCount: prevState.successCount - (props?.params?.successCount || 0),
                  skillA: prevState.skillA - (props?.params?.skillA || 0),
                  skillB: prevState.skillB - (props?.params?.skillB || 0),
                  skillC: prevState.skillC - (props?.params?.skillC || 0),
                  skillD: prevState.skillD - (props?.params?.skillD || 0),
                  skillE: prevState.skillE - (props?.params?.skillE || 0),
                })
              )
              props?.mutiOptionImgs.splice(props?.mutiOptionImgs.indexOf(props?.id),1)
            } else {
              props?.click?.(
                (prevState) => ({
                  number: prevState.number + 1,
                  successCount: prevState.successCount + (props?.params?.successCount || 0),
                  skillA: prevState.skillA + (props?.params?.skillA || 0),
                  skillB: prevState.skillB + (props?.params?.skillB || 0),
                  skillC: prevState.skillC + (props?.params?.skillC || 0),
                  skillD: prevState.skillD + (props?.params?.skillD || 0),
                  skillE: prevState.skillE + (props?.params?.skillE || 0),
                })
              )
              props?.mutiOptionImgs.push(props?.id)
              // play()            
            }
            setSelect((prevIsSelected) => !prevIsSelected)
          }
        }}
      >
        <Img 
          className={`${ isSelected ? 'active' : '' }`}
          src={props.icon} />
        <Check src={ic_check} className='check' />
      </MutiButton>
      <OptionP>{props.btnTitle}</OptionP>
      
    </OptionGroup>
  )
}

export default MutiOption
