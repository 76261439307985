import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import { checkGameStatus } from "../utility"
import styled from 'styled-components'

import { Images, Styles, Audios } from '../Themes'

import Logo from "../components/Logo"
import MutiWrapperTransition from "../components/MutiWrapperTransition"
import Skip from "../components/Button/Skip"

import { IProps } from "../types"
import { ITransitions } from '../types/index';

import stores from '../Option/All/stores'
import promotions from '../Option/promotionOptions'
import transparent from '../img/promote/transparent.png'

// let audio = new Audio(`${Audios.done}`);

const Wrapper = styled(Styles.wrapper())``

const options: ITransitions = {
  A: [{
    portrait: `${Images.avatar_boss_gym}`,
    img: ``, 
    href: '/halfyear/A', 
    text: '善用網路行銷才能吸引年輕人關注，業績成長！',
  }],
  B: [{
    portrait: `${Images.avatar_boss_cafe}`,
    img: ``, 
    href: '/halfyear/B',
    text: '善用網路行銷才能吸引年輕人關注，業績成長！',
  }],
  C: [{
    portrait: `${Images.avatar_boss_clothes}`,
    img: ``, 
    href: '/halfyear/C',
    text: '善用網路行銷才能吸引年輕人關注，業績成長！',
  }],
}

const PromotionTransitionsPage = (props: IProps) => {

  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  const [img, setImg] = useState('')
  const [mutiOptionImgA, setMutiOptionImgA] = useState('')
  const [mutiOptionImgB, setMutiOptionImgB] = useState('')
  const [mutiOptionImgC, setMutiOptionImgC] = useState('')
  const classList =['youtube','chat','sale','idol','fb']
  // const [playBgm, setPlayBgm] = useState(true)

  useEffect(() => {
    if(state){
      setImg(stores[state.store].img)
    }
    if(state.MutiOptionImgA){
      setMutiOptionImgA(promotions[id][state.MutiOptionImgA].img)
    }
    if(state.MutiOptionImgB !== 'undefined'){
      setMutiOptionImgB(promotions[id][state.MutiOptionImgB].img)
    }else{
      setMutiOptionImgB(transparent)
    }
    if(state.MutiOptionImgC !== 'undefined'){
      setMutiOptionImgC(promotions[id][state.MutiOptionImgC].img)
    }else{
      setMutiOptionImgC(transparent)
    }
  },[])

  // if(!playBgm){
  //   audio.muted = true;
  // }
  // if(playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 0);
  //     setPlayBgm(false)
  // }
  
  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  
  return (
    <Wrapper>
        <Logo onClick={()=>history.go(-10)} />
        <Skip params={promotions[`${id}SkipTrans`][0].params} />
        {options[id].map((i, index) => (
            <MutiWrapperTransition
              key={`promotionTransition-${id}-${index}`}
              portrait={i.portrait}
              img={img}
              text={i.text}
              href={i.href}
              mutiOptionImgA={mutiOptionImgA}
              mutiOptionImgB={mutiOptionImgB}
              mutiOptionImgC={mutiOptionImgC}
              imgA={`${classList[state.MutiOptionImgA]}`}
              imgB={`${classList[state.MutiOptionImgB]}`}
              imgC={`${classList[state.MutiOptionImgC]}`}
            />
        ))}
    </Wrapper>
  )
}


export default PromotionTransitionsPage
