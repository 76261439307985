import styled from 'styled-components'
import Metrics from './Metrics';
import Colors from './Colors';

const type = {
  base: '-apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, Oxygen, Ubuntu, "Open Sans", "Helvetica Neue","Noto Sans TC","微軟正黑體", sans-serif',
}

const Fonts = {
  h1: () => styled.h1`
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 45px;
    @media (max-width: ${Metrics.tablet}){
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 32px;
    }
  `,
  h3: () => styled.h3`
    font-family: ${type.base} ;
    -webkit-font-smoothing: antialiased;
    color: ${Colors.TitleBlack};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 32px;
    margin: 0;
    @media (max-width: ${Metrics.tablet}){
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
    }
  `,
  p: () => styled.p`
    font-family: ${type.base} ;
    font-size: 20px;
    margin: 0;
    letter-spacing: 1px;
    line-height: 28px;
    @media (max-width: ${Metrics.tablet}){
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
    }
  `,
  optionText: () => styled.p`
    font-family: ${type.base} ;
    color: ${Colors.TitleBlack};
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 32px;
    margin: 0;
    text-align: left;
    margin-left: 10px;
    @media (max-width: ${Metrics.tablet}){
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        margin-left: inherit;
        width: 100%
    }
  `,
  label: () => styled.label`
    font-family: ${type.base} ;
    font-size: 24px;
    z-index: 5;
    color: ${Colors.TitleBlack};
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;

    @media (max-width: ${Metrics.tablet}){
        font-size: 14px;
        letter-spacing: 1.17px;
        line-height: 20px;
        margin: 4px auto;
    }
  `,
  button: () => styled.button`
    font-family: ${type.base} ;
  `
}

export default Fonts;