import React, { useState } from 'react'
import { IContextProps } from '../types/index';

export const StoreContext = React.createContext<IContextProps>({
  score: {
    path: '',
    successCount: 0,
    skillA: 0,
    skillB: 0,
    skillC: 0,
    skillD: 0,
    skillE: 0,
  },
  setScore: () => {},
})

export default ({ children } : any) => {
  const [score, setScore] = useState({
    path: '',
    successCount: 0,
    skillA: 0,
    skillB: 0,
    skillC: 0,
    skillD: 0,
    skillE: 0,
  })
  return <StoreContext.Provider value={{score, setScore}}>{children}</StoreContext.Provider>
}