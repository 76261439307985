import React from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import Button from "../Button/Button"
import { IMutiOptionArea } from '../../types/index';
import { Metrics, Fonts, Styles } from '../../Themes'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled(Styles.OptionGroupWrapper())`
`
const Card = styled(Styles.OptionGroupCard())`
`
const TitleArea = styled(Styles.OptionGroupTitleArea())`
`
const Title = styled(Styles.OptionGroupTitle())`
`
const OptionGroup = styled(Styles.OptionGroupArea())`
    @media (max-width: ${Metrics.tablet}){
        .slick-slide{
            max-width: 90px; 
        }
    }
`
const ButtonArea = styled.div`
    justify-self: end;
`

const OptionText = ({children, ...props}:IMutiOptionArea) => {
  const history = useHistory();
  return (
    <Wrapper>
        <Card>
            <TitleArea>
                <Title>{props?.title}</Title>
                <ButtonArea>
                    <div
                        onClick={() => {
                            if (props.clickable) {
                            if (history.location.state) {
                                const { work, store, employee, path, successCount, skillA, skillB, skillC, skillD, skillE} = history.location.state as any;
                                history.push({ pathname: props.href, state:{
                                work: work || props.params?.work || '',
                                store: store || props.params?.store || '',
                                employee: employee || props.params?.employee || '',
                                path: path || props.params?.path || '',
                                successCount: successCount ? successCount + props.params?.successCount : props.params?.successCount,
                                skillA: skillA ? skillA + props.params?.skillA : props.params?.skillA,
                                skillB: skillB ? skillB + props.params?.skillB : props.params?.skillB,
                                skillC: skillC ? skillC + props.params?.skillC : props.params?.skillC,
                                skillD: skillD ? skillD + props.params?.skillD : props.params?.skillD,
                                skillE: skillE ? skillE + props.params?.skillE : props.params?.skillE,
                                MutiOptionImgA: props.params?.MutiOptionImgA || '',
                                MutiOptionImgB: props.params?.MutiOptionImgB || '',
                                MutiOptionImgC: props.params?.MutiOptionImgC || '',
                                }});
                            } else {
                                history.push({ pathname: props.href, state:{
                                work: props.params?.work || '',
                                store: props.params?.store || '',
                                employee: props.params?.employee || '',
                                path: props.params?.path || '',
                                successCount: props.params?.successCount || 0,
                                skillA: props.params?.skillA || 0,
                                skillB: props.params?.skillB || 0,
                                skillC: props.params?.skillC || 0,
                                skillD: props.params?.skillD || 0,
                                skillE: props.params?.skillE || 0,
                                MutiOptionImgA: props.params?.MutiOptionImgA || '',
                                MutiOptionImgB: props.params?.MutiOptionImgB || '',
                                MutiOptionImgC: props.params?.MutiOptionImgC || '',
                                }});
                            }
                            } else {
                            alert('請至少選擇一個項目')
                            }
                        }
                        }
                        style={{ margin: `0 auto` }}
                    >
                        <Button
                            clickable={props.clickable}
                            btnTitle='確認' 
                        />
                    </div>
                </ButtonArea>
            </TitleArea>
            <OptionGroup>
                <Slider
                    arrows={false}
                    infinite={false}
                    slidesToShow={4}
                    slidesToScroll={1}
                    responsive= {[
                        {
                            breakpoint: 1280,
                            settings: {
                              slidesToShow: 4,
                            }
                        },
                        {
                            breakpoint: 960,
                            settings: {
                              slidesToShow: 3,
                            }
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 4,
                          }
                        },
                        {
                          breakpoint: 568,
                          settings: {
                            slidesToShow: 4,
                          }
                        },
                        {
                            breakpoint: 425,
                            settings: {
                              slidesToShow: 4,
                            }
                        },
                        {
                            breakpoint: 375,
                            settings: {
                              slidesToShow: 3,
                            }
                        }
                      ]}
                >
                    {children}
                </Slider>
            </OptionGroup>
        </Card>
    </Wrapper>
  )
}

export default OptionText
