import React, { useState, useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { Images, Metrics, Styles } from '../Themes'
import { isIE } from 'react-device-detect';
import OptionTextIE from "./Option/OptionTextIE"
import OptionTextN from "./Option/OptionTextNull"
import Next from "../components/Button/Next"
import { useHistory } from "react-router-dom";
import { IMutiTransitionItem } from '../types/index';

import employees from '../Option/All/employees'

const Wrapper = styled(Styles.TransitionWrapper())``

const ImageArea = styled(Styles.TransitionImgArea())`
  height: 40vh;
  @media (max-width: ${Metrics.tablet}){
    margin-top: 30px;
  }
`
const Bg = styled(Styles.TransitionBg())`
  bottom: 0;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    top: 0
  }

`
const Img =  styled(Styles.TransitionImg())`
  @media (max-width: ${Metrics.tablet}){
    height: 100%;
  }
  @media (max-height: 960px){
    height: 100%;
  }
`
const Employee = styled(Styles.MutiEmployee())`
  left: 54%;
  bottom: 0%;
  @media (max-width: ${Metrics.tablet}){
    left: 45%;
    bottom: 5%;
    max-height: 180px;
  }
`
const OptionImgGroup = styled(Styles.MutiImgGroup())`
`
const OptionImgA = styled(Styles.MutiOptionImgTrans())`
  bottom: 55%;
  left: 25%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 45%;
    left: 10%;
  }
  @media (max-height: 800px){
    max-width: 90px;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    width: auto;
    max-width: 60px;
  }
`

const OptionImgB = styled(Styles.MutiOptionImgTrans())`
  bottom: 15%;
  left: 20%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 35%;
    left: 35%;
  }
  @media (max-height: 800px){
    max-width: 90px;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    width: auto;
    max-width: 60px;
  }
`
const OptionImgC = styled(Styles.MutiOptionImgTrans())`
  bottom: 20%;
  left: 75%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 60%;
    left: 50%;
  }
  @media (max-height: 800px){
    max-width: 90px;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    width: auto;
    max-width: 60px;
  }
`
const OptionArea = styled(Styles.TransitionOptionArea())`
  height: 35vh;
  @media (max-width: ${Metrics.tablet}){
    height: auto;
    max-height: inherit;
  }
  
`

const MutiTransitionWrapper = ( props: IMutiTransitionItem) => {
  const history = useHistory();
  const state = history.location.state as any
  const [employee, setEmployee] = React.useState('')
  const [ text, setText ] = useState('')

  useEffect(() => {
    if(state.employee){
      setEmployee(employees[state.employee].body_img)
    }
    setTimeout(() => {
      setText(`${props?.text}`)
    ;}, 1000)
  }, [])
  
  if(isIE){
    const OptionText = lazy(() => import("./Option/OptionTextIE"));
    return (
      <Wrapper>
        <ImageArea>
            <Bg src={`${Images.bg}`} className=''  />
            <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s' />
            <Employee src={employee} className='animate__fadeIn animate__animated animate__delay-1s' />
            <OptionImgGroup className='animate__fadeIn animate__animated animate__delay-2s'>
              <OptionImgA src={props.mutiOptionImgA} className={`animate__pulse animate__animated ${props.imgA}`} />
              <OptionImgB src={props.mutiOptionImgB} className={`animate__pulse animate__animated ${props.imgB}`} />
              <OptionImgC src={props.mutiOptionImgC} className={`animate__pulse animate__animated ${props.imgC}`} />
            </OptionImgGroup>
        </ImageArea>
        <OptionArea>
          <Suspense fallback={<OptionTextN portrait={props?.portrait} text={text}/>}>
            <OptionText
              portrait={props?.portrait}
              text={text}
            />
          </Suspense>
          <div onClick={() => history.location.state 
            ? history.push({ pathname: `${props.href}`, state: history.location.state }) 
            : history.push({ pathname: `${props.href}`})}
            style={{ alignSelf: `end` }} className='next'
          >
            <Next />
          </div>
        </OptionArea>
      </Wrapper>
    )
  }else{
    const OptionText = lazy(() => import("./Option/OptionText"));
    return (
      <Wrapper>
        <ImageArea>
            <Bg src={`${Images.bg}`} className=''  />
            <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s' />
            <Employee src={employee} className='animate__fadeIn animate__animated animate__delay-1s' />
            <OptionImgGroup className='animate__fadeIn animate__animated animate__delay-2s'>
              <OptionImgA src={props.mutiOptionImgA} className={`animate__pulse animate__animated ${props.imgA}`} />
              <OptionImgB src={props.mutiOptionImgB} className={`animate__pulse animate__animated ${props.imgB}`} />
              <OptionImgC src={props.mutiOptionImgC} className={`animate__pulse animate__animated ${props.imgC}`} />
            </OptionImgGroup>
        </ImageArea>
        <OptionArea>
          <Suspense fallback={<OptionTextN portrait={props?.portrait} text={text}/>}>
            <OptionText
              portrait={props?.portrait}
              text={text}
            />
          </Suspense>
          <div onClick={() => history.location.state 
            ? history.push({ pathname: `${props.href}`, state: history.location.state }) 
            : history.push({ pathname: `${props.href}`})}
            style={{ alignSelf: `end` }} className='next'
          >
            <Next />
          </div>
        </OptionArea>
      </Wrapper>
    )
  }

  
}

export default MutiTransitionWrapper
