import React,{ useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom";
import styled from 'styled-components'
import Option from "../components/Option"
import Logo from "../components/Logo"
import OptionTwoWrapper from "../components/OptionTwoWrapper"
import { Styles, Audios } from '../Themes'
import { IProps } from "../types"
import { checkGameStatus } from "../utility"
import Skip from "../components/Button/Skip"

import options from '../Option/moneyOptions'

import bank_100_01 from '../img/main/bank_100_01.png'
import bank_300_01 from '../img/main/bank_300_01.png'
import investor from '../img/main/investor.png'

import avatar_uncle from '../img/avatar/uncle.svg'
import avatar_bank from '../img/avatar/bank.svg'
import avatar_investor from '../img/avatar/investor.svg'

// let audio = new Audio(`${Audios.bo}`);

const Wrapper = styled(Styles.wrapper())``

const OptionDiv = styled.div`
  margin: 0 auto;
  outline: none;
`

const Money = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;

  const [selected, setSelected] = useState('')
  const [portrait, setPortrait] = useState('')
  const [text, setText] = useState('')
  const [portrait_2, setPortrait_2] = useState('')
  const [text_2, setText_2] = useState('')
  const [img, setImg] = useState('')
  const [params,setParams] = useState()
  const [clickable, setClickable] = useState(false)
  const imgList = [bank_100_01,bank_300_01,investor]
  const avaterList = [avatar_uncle,avatar_bank,avatar_investor]
  // const [playBgm, setPlayBgm] = useState(true)
  
  useEffect(() => {
      
    setImg(`${options[id][0].firstImg}`)
    setText('該從哪裡獲得這些資金呢？跟親友借貸？還是向銀行貸款？又或者是找投資人呢？這三者又有什麼不同？')
    setPortrait(`${options[id][0].portrait}`)

    imgList.forEach((image) => {
      new Image().src = image
    });

    avaterList.forEach((image) => {
      new Image().src = image
    });

    for (let i = 0; i < 3 ; i++) {
      if( selected === `${i}`){
        setImg(imgList[i])
        setText_2(options[id][i].text)
        setText(options[id][i].text_2)
        setPortrait_2(options[id][i].portrait)
        setPortrait(avaterList[i])
        setClickable(options[id][i].clickable)
        setParams(options[id][i].params)
      }
    }
  
  })

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 1000);
  //   setPlayBgm(false)
  // }

  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  
  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-12)}/>
      <Skip params={options[`${id}Skip`][0].params} />
      <OptionTwoWrapper 
        className='animate__fadeIn animate__animated animate__delay-1s animate__faster'
        className_2='animate__fadeInRight animate__animated animate__faster'
        title='請選擇合作夥伴'
        img={img}
        portrait={portrait}
        text={text}
        portrait_2={portrait_2}
        text_2={text_2}
        href={`/MoneyTransition/${id}`}
        clickable={clickable}
        params={params}
      >
        {options[id].map((i, index) => (
          <OptionDiv key={`${id}-money-${index}`} id={`${index}`} onClick={() =>setSelected(`${index}`)}>
            <Option
              // clickable={i.clickable}
              img={i.icon}
              // href={i.href}
              btnTitle={i.btnTitle}
              text={i.text}
              // params={i.params}
              id={`emplyee_${index}`}
              for={`emplyee_${index}`}
            />
          </OptionDiv>
        ))}
      </OptionTwoWrapper>
    </Wrapper>
    // <>
    //   <TitleArea
    //     title='資金來源'
    //     text='要銀行貸款 還是 投資人資助 比較好呢?'
    //   />
    //   <OptionContainer>
    //     {/* {options[id].map((i, index) => (
    //       <Option
    //         clickable={i.clickable}
    //         key={`${id}-money-${index}`}
    //         img={i.img}
    //         href={i.href}
    //         btnTitle={i.btnTitle}
    //         text={i.text}
    //         params={i.params}
    //       />
    //     ))} */}
    //   </OptionContainer>
  )
}

export default Money
