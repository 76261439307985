import { Images } from '../../Themes'

const money = [
  {
    img: `${Images.bank_100_02}`,
    text: '有了親友的協助，獲得一筆尚且夠用的資金，趕快看看要怎麼運用在優化店面上，提高能見度。'
  },
  {
    img: `${Images.bank_300_02}`,
    text: '快速到位的銀行的資金，流程辦理起來也很簡單。有了足夠的資金注入，趕快來看看有什麼方法能優化店面，能提高能見度。'
  },
  {
    img: `${Images.investor}`,
    text: '投資人同意我的提案，雖然申請的資金也到位，但同時投資人要求入股。不管怎樣，有了資金之後，要趕快找方法能優化店面，能提高能見度。'
  },
]

export default money;