import { IMutiOption } from '../types/index';
import { Images } from '../Themes'

const options: IMutiOption = {
    A: [
      {
        img: `${Images.upgrade_01}`, icon:`${Images.chair}`, btnTitle: '特色裝潢',
        text: '同業競爭性高，要打造自己的特色，才會凸顯我和別人的不同。',
        params: {
          successCount: 1,
          skillA: -1,
          skillB: 0,
          skillC: 0,
          skillD: -1,
          skillE: 1,
        }
      },
      {
        img: `${Images.upgrade_02}`, icon:`${Images.app}`, btnTitle: 'App 開發',
        text: '引入 App 系統雖然有一定的開發成本，但也可以省去日後的人事開銷。',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: -1,
          skillD: 0,
          skillE: 0,
        }
      },
      {
        img: `${Images.upgrade_03}`, icon:`${Images.member}`, btnTitle: '專屬服務',
        text: '為上班族或學生族群打造的客製化健身房，感覺會讓顧客的運動體驗更好。',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: -1,
          skillD: 0 ,
          skillE: 1,
        }
      },
      {
         img: `${Images.upgrade_04}`, icon:`${Images.arrow}`, btnTitle: '動線優化',
         text: '改善室內的動線，讓顧客運動起來比較寬敞與舒適。',
        params: {
          successCount: 1,
          skillA: 1,
          skillB: -1,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      },
    ],
    B: [
      {
         img: `${Images.upgrade_01}`, icon:`${Images.chair}`, btnTitle: '特色裝潢',
         text: '同業競爭性高，要打造自己的特色，才會凸顯我和別人的不同。',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 1,
          skillC: 1,
          skillD: 1,
          skillE: 1,
        }
      },
      {
         img: `${Images.upgrade_02}`, icon:`${Images.app}`, btnTitle: 'App 開發',
         text: '透過外送平台，除了提高方便性，也能讓更多人能接觸到我的咖啡，進而推廣給更多人。',
        params: {
          successCount: 1,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 1,
          skillE: -1,
        }
      },
      {
         img: `${Images.upgrade_03}`, icon:`${Images.member}`, btnTitle: '專屬服務',
         text: '專注於品質，或許能讓顧客感覺到這是家有溫度的店。',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 1,
          skillC: 1,
          skillD: 1,
          skillE: 1,
        }
      },
      {
         img: `${Images.upgrade_04}`, icon:`${Images.arrow}`, btnTitle: '動線優化',
         text: '改善室內的動線，讓顧客消費起來也會比較舒適。',
        params: {
          successCount: 1,
          skillA: 1,
          skillB: -1,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      },
    ],
    C: [
      {
         img: `${Images.upgrade_01}`, icon:`${Images.chair}`, btnTitle: '特色裝潢',
         text: '同業競爭性高，要打造自己的特色，才會凸顯我和別人的不同。',
        params: {
          successCount: 1,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      },
      {
         img: `${Images.upgrade_02}`, icon:`${Images.app}`, btnTitle: 'App 開發',
         text: '除了實體店面，提供線上網拍，可以讓顧客有更多的購買方式，大大提升方便性，或許也能藉此吸引到更多人。',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      },
      {
         img: `${Images.upgrade_03}`, icon:`${Images.member}`, btnTitle: '專屬服務',
         text: '提供專人的穿搭服務，或許可以是主打的特色，讓顧客不再煩惱什麼服飾才適合自己。',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 2,
          skillE: 1,
        }
      },
      {
         img: `${Images.upgrade_04}`, icon:`${Images.arrow}`, btnTitle: '動線優化',
         text: '改善室內的動線，讓顧客消費起來也感到舒適。',
        params: {
          successCount: 1,
          skillA: 1,
          skillB: 0,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      },
    ],
    ASkip: [
      {
        img: '', icon: '', btnTitle: '',text: '',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: -1,
          skillD: 0,
          skillE: 0,
        }
      }
    ],
    BSkip: [
      {
        img: '', icon: '', btnTitle: '',text: '',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 1,
          skillC: 1,
          skillD: 1,
          skillE: 1,
        }
      }
    ],
    CSkip: [
      {
        img: '', icon: '', btnTitle: '',text: '',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0,
        }
      }
    ],
    ASkipTrans: [
      {
        img: '', icon: '', btnTitle: '',text: '',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0,
        }
      }
    ],
    BSkipTrans: [
      {
        img: '', icon: '', btnTitle: '',text: '',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0,
        }
      }
    ],
    CSkipTrans: [
      {
        img: '', icon: '', btnTitle: '',text: '',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0,
        }
      }
    ],
}

export default options;