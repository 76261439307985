import React from "react"
import { Redirect, useHistory } from "react-router-dom";
import styled from 'styled-components'
import { Metrics, Styles, Images, Colors } from '../Themes'
import ApexChart from 'react-apexcharts'
import Logo from "../components/Logo"
import SummaryDiv from '../components/Summary'
import { checkGameStatus, getPersonility } from "../utility"
import { IProps } from "../types";

const Wrapper = styled(Styles.wrapper())`
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${Metrics.tablet}){
    grid-template-columns: 100%;
    height: 95vh;
    overflow-x: hidden; 
  }
`

const Container = styled.section`
  max-width: ${Metrics.desktopM};
  display: grid;
  margin: 0 auto;
  grid-template-columns: 60% 40%;
  position: relative;
  height: 85vh;
  align-items: center;
  justify-items: center;
  margin-top: 75px;
  overflow-x: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .rader-label{
    height: 100px;
  }
  @media (max-width: ${Metrics.tablet}){
    grid-template-columns: 100%;
    background: #FAFAFA;
    height: 85vh;
    overflow-x: hidden;
    margin-top: 60px;
    align-items: start;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    display: -ms-grid;
    -ms-grid-columns: 50% 50%;
  }
  @media (max-width: ${Metrics.tablet}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    -ms-grid-columns: 100%;
  }
`
const RaderMo = styled.div`
  display: none;
  min-width: 525px;
  position: relative;
  left: 52%;
  transform: translateX(-50%);
  margin: 0 auto;
  img{
    position: absolute;
    top: 0.5%;
    left: 15%;
    width: 67%;
  }
  @media (max-width: ${Metrics.tablet}){
    display: block;
    img{
      top: 0.5%;
      left: 14%;
      width: 70%;
    }
  }
  @media (max-width: 425px){
    img{
      top: 1%;
      left: 15%;
      width: 67%;
    }
  }
  @media (max-width: 375px){
    min-width: 500px;
    img{
      top: 1.5%;
      left: 15.5%;
      width: 66%;
    }
  }
  @media (max-width: 320px){
    min-width: 475px;
    left: 55%;
    img{
      top: 2%;
      left: 15.5%;
      width: 65%;
    }
  }
  @media (max-width: 271px){
    min-width: 400px;
    left: 55%;
    img{
      top: 4%;
      left: 16.5%;
      width: 62%;
    }
  }
`
const Rader = styled.div`
  display: block;
  width: 50vw;
  max-width: 800px;
  margin-top: 70px;
  .rader-label{
    margin: 10px;
    height: 100px;
  }
  img{
    position: absolute;
    top: -3.5%;
    left: 12%;
    width: 74%;
  }
  @media (max-width: 1280px){
    img{
      position: absolute;
      top: -2%;
      left: 12.5%;
      width: 72%;
    }
  }
  @media (max-width: 1119px){
    img{
      top: -1%;
      left: 13%;
      width: 70%;
    }
  }
  @media (max-width: 980px){
    img{
      top: 0%;
      left: 14%;
      width: 68%;
    }
  }
  @media (max-width: 980px){
    img{
      top: 2%;
      left: 15.5%;
      width: 65%;
    }
  }
  @media (max-width: ${Metrics.tablet}){
    display: none;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    -ms-grid-column: 2;
    -ms-grid-column-align: start
  }
`

const Summary = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;

  const personility = getPersonility([
    { id: 'A', point: state?.skillA || 0 },
    { id: 'B', point: state?.skillB || 0 },
    { id: 'C', point: state?.skillC || 0 },
    { id: 'D', point: state?.skillD || 0 },
    { id: 'E', point: state?.skillE || 0 },
  ])

  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  const chartData = {
    series: [{
      name: '能力',
      data: [state?.skillA, state?.skillB, state?.skillC, state?.skillD, state?.skillE],
    }],
    options: {
      plotOptions: {
        radar: {
          polygons: {
            show: true,
            strokeColors: 'transparent',
            connectorColors: 'transparent',
            fill: {
              colors: ['#FAFAFA', '#FAFAFA']
            }
          },
        }
      },
      stroke: {
        show: false,
        opacity: 0,
       
      },
      markers: {
        size: 0,
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'image',
        colors: ['#000'],
        opacity: 1,
        image: {
          src: [`${Images.yellow_bg}`],
          width: 120,
          height: 120,
        }
      },
      yaxis: {
        max: 12,
        show: false,
      },
      xaxis: {
        categories: ['判斷力', '膽量', '忍耐力', '觀察力', '創造力'],
        labels: {
          show: true,
          style: {
            fontSize: '14px',
            fontFamily: 'PingFang TC,Helvetica, Arial, sans-serif',
            colors: `${Colors.TitleBlack}`,
            cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: -1,
          offsetY: 1,
         
          
        },
        
      }
    },
  };
  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-18)}/>
      <Container> 
        <SummaryDiv
          title={`${personility[0]}`}
          title_2={`${personility[1]}`}
          text={`${personility[2]}`}
          id={id}
        >
          <RaderMo>
            <ApexChart
              options={chartData.options}
              series={chartData.series}
              type="radar"
              height='auto'
              width="100%"
            />
            <img src={`${Images.data_bg}`} alt='' />
          </RaderMo>
        </SummaryDiv>
          <Rader className='animate__fadeIn animate__animated animate__delay-1s animate__faster'>
            <ApexChart
              options={chartData.options}
              series={chartData.series}
              type="radar"
              height='auto'
              width="100%"
            />
            <img src={`${Images.data_bg}`} alt='' />
          </Rader>
      </Container>
    </Wrapper>
  )
}

export default Summary
