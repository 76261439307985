const Audio = {

    bgm: require('../audio/bgm/bgm.mp3'),

    alert: require('../audio/sound/alert/alert_2.mp3'),

    build: require('../audio/sound/build/Decorate.mp3'),

    click: require('../audio/sound/click/coin.mp3'),

    done: require('../audio/sound/done/Magic_Chime_.mp3'),

    money: require('../audio/sound/money/Cash.mp3'),

    bo: require('../audio/sound/problem/bo.mp3'),

    success: require('../audio/sound/result/sucess.mp3'),
    fail: require('../audio/sound/result/fail.mp3'),

    speak: require('../audio/sound/speak/speak.mp3'),

    year: require('../audio/sound/year/tear.mp3'),
}

export default Audio;