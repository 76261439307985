import { Images } from '../../Themes'

const employees = [
  {
    portrait: `${Images.avatar_gym_01}`,
    body_img: `${Images.body_gym_01}`,
    img: `${Images.gym_pt}`
  },
  {
    portrait: `${Images.avatar_gym_02}`,
    body_img: `${Images.body_gym_02}`,
    img: `${Images.gym_fulltime}`
  },
  {
    portrait: `${Images.avatar_gym_03}`,
    body_img: `${Images.body_gym_03}`,
    img: `${Images.gym_master}`
  },
  {
    portrait: `${Images.avatar_cafe_01}`,
    body_img: `${Images.body_cafe_01}`,
    img: `${Images.cafe_pt}`
  },
  {
    portrait: `${Images.avatar_cafe_02}`,
    body_img: `${Images.body_cafe_02}`,
    img: `${Images.cafe_fulltime}`
  },
  {
    portrait: `${Images.avatar_cafe_03}`,
    body_img: `${Images.body_cafe_03}`,
    img: `${Images.cafe_master}`
  },
  { 
    portrait: `${Images.avatar_clothes_01}`,
    body_img: `${Images.body_clothes_01}`,
    img: `${Images.clothes_pt}`
  },
  {
    portrait: `${Images.avatar_clothes_02}`,
    body_img: `${Images.body_clothes_02}`,
    img: `${Images.clothes_fulltime}`
  },
  {
    portrait: `${Images.avatar_clothes_03}`,
    body_img: `${Images.body_clothes_03}`,
    img: `${Images.clothes_master}`
  },
]

export default employees;