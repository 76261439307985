import React from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import { Colors, Metrics, Fonts } from '../../Themes'
import { ISkip } from '../../types/index';

const Button = styled(Fonts.button())`
    position: absolute;
    top: 24px;
    right: 24px;
    margin-left: 20px;
    background: ${Colors.W100};
    margin-bottom: 30px;
    padding: 8px 16px;
    border-radius: 22px;
    border: 1px solid ${Colors.BtnGreen};
    z-index: 10000;
    transition: .2s;
    transform: scale(1,1);
    outline: none;
    &:hover {
        transform: scale(0.95, 0.95);
        cursor: pointer;
    }
    &.active{
        transform: scale(1, 1);
    }
    @media (max-width: ${Metrics.tablet}){
        margin: 0 auto;
        font-size: 12px;
        line-height: 17px;
        padding: 4px 11px;
`
const Text = styled(Fonts.p())`
    color: ${Colors.BtnGreen};
`

const Skip = (props: ISkip) => {
    const history = useHistory()
    return (
        <Button onClick={() => {if (history.location.state) {
            const { work, store, employee, money, path, successCount, skillA, skillB, skillC, skillD, skillE } = history.location.state as any;
                history.push({ pathname: successCount === 0 ? '/success' : '/failed', state:{
                work: work ||  '',
                store: store ||  '',
                employee: employee ||  '',
                money: money || '',
                path: path || '',
                successCount: successCount ? successCount + props.params?.successCount : props.params?.successCount,
                skillA: skillA ? skillA + props.params?.skillA : props.params?.skillA,
                skillB: skillB ? skillB + props.params?.skillB : props.params?.skillB,
                skillC: skillC ? skillC + props.params?.skillC : props.params?.skillC,
                skillD: skillD ? skillD + props.params?.skillD : props.params?.skillD,
                skillE: skillE ? skillE + props.params?.skillE : props.params?.skillE,
            }});
        }}}>
            <Text>跳過創業過程</Text>
        </Button>
    )
}

export default Skip