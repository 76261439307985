import React, { useState, useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { Images, Metrics, Styles } from '../Themes'
import OptionTextIE from "./Option/OptionTextIE"
import OptionTextN from "./Option/OptionTextNull"
import MutiOptionGroup from "./Option/MutiOptionArea"
import { useHistory } from "react-router-dom";
import { IMutiOptionWrapper } from '../types/index';
import { isIE } from 'react-device-detect';

import employees from '../Option/All/employees'

const Wrapper = styled(Styles.OptionWrapper())``

const ImageArea = styled(Styles.MutiOptionImages())``

// const Bg = styled(Styles.MutiBg())``

const Img = styled(Styles.MutiImg())``

const Employee = styled(Styles.MutiEmployee())`
  left: 54%;
  bottom: 0%;
  @media (max-width: ${Metrics.tablet}){
    left: 45%;
    bottom: 0%;
  }
`

const OptionImgGroup = styled(Styles.MutiImgGroup())`
  &.youtube{
    .youtube{
      opacity: 1;
    }
  }
  &.chat{
    .chat{
      opacity: 1;
    }
  }
  &.sale{
    .sale{
      opacity: 1;
    }
  }
  &.idol{
    .idol{
      opacity: 1;
    }
  }
  &.fb{
    .fb{
      opacity: 1;
    }
  }
`

const OptionImgA = styled(Styles.MutiOptionImg())`
  bottom: 60%;
  left: 60%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 60%;
    left: 55%;
  }
`
const OptionImgB = styled(Styles.MutiOptionImg())`
  bottom: 20%;
  left: 70%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 20%;
    left: 75%;
  }
`
const OptionImgC = styled(Styles.MutiOptionImg())`
  bottom: 55%;
  left: 25%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 50%;
    left: 12%;  
  }
`
const OptionImgD = styled(Styles.MutiOptionImg())`
  bottom: 50%;
  left: 40%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 25%;
    left: 38%;
  }
`
const OptionImgE = styled(Styles.MutiOptionImg())`
  bottom: 20%;
  left: 40%;
  @media (max-width: ${Metrics.tablet}){
    bottom: 0%;
    left: 10%;
  }
`

const OptionArea = styled(Styles.MutiOptionArea())`
`

const OptionWrapper = ({children, ...props}: IMutiOptionWrapper) => {
  const history = useHistory();
  const state = history.location.state as any
  const [employee, setEmployee] = useState('')
  const [ text, setText ] = useState('')

  useEffect(() => {
    if(state){
      setEmployee(employees[state.employee].body_img)
    }
    if( !props.clickable ){
      setTimeout(() => {
        setText(`${props?.text}`)
      ;}, 1500)
    }
    return () => {
      window.onresize = null
    }

  })
  if(isIE){
    const OptionText = lazy(() => import("./Option/OptionTextIE"));
    return (
      <Wrapper>
          <ImageArea>
            {/* <Bg src={`${Images.bg}`} className='animate__fadeIn animate__animated animate__delay-1s' /> */}
            <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s' />
            <Employee src={employee} className='animate__bounceInRight animate__animated animate__delay-1s' />
            <OptionImgGroup className={`${props.imgSelected}`}>
              <OptionImgA src={props.mutiOptionImgA} className={`animate__pulse animate__animated youtube`} />
              <OptionImgB src={props.mutiOptionImgB} className={`animate__pulse animate__animated chat`} />
              <OptionImgC src={props.mutiOptionImgC} className={`animate__pulse animate__animated sale`} />
              <OptionImgD src={props.mutiOptionImgD} className={`animate__pulse animate__animated idol`} />
              <OptionImgE src={props.mutiOptionImgE} className={`animate__pulse animate__animated fb`} />
            </OptionImgGroup>
          </ImageArea>
          <OptionArea>
              <Suspense fallback={<OptionTextN
                  portrait={props?.portrait}
                  text={ props.clickable ? `${props?.text}` : `${text}` }
                />}>
              <OptionText
                portrait={props?.portrait}
                text={ props.clickable ? `${props?.text}` : `${text}` }
              />
           </Suspense>
            <MutiOptionGroup
              clickable={props?.clickable}
              title={props?.title}
              href={props?.href}
              params={props?.params}
            >
              {children}
            </MutiOptionGroup>
          </OptionArea>
      </Wrapper>
    )
  }else{
    const OptionText = lazy(() => import("./Option/OptionText"));
    return (
      <Wrapper>
          <ImageArea>
            {/* <Bg src={`${Images.bg}`} className='animate__fadeIn animate__animated animate__delay-1s' /> */}
            <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s' />
            <Employee src={employee} className='animate__bounceInRight animate__animated animate__delay-1s' />
            <OptionImgGroup className={`${props.imgSelected}`}>
              <OptionImgA src={props.mutiOptionImgA} className={`animate__pulse animate__animated youtube`} />
              <OptionImgB src={props.mutiOptionImgB} className={`animate__pulse animate__animated chat`} />
              <OptionImgC src={props.mutiOptionImgC} className={`animate__pulse animate__animated sale`} />
              <OptionImgD src={props.mutiOptionImgD} className={`animate__pulse animate__animated idol`} />
              <OptionImgE src={props.mutiOptionImgE} className={`animate__pulse animate__animated fb`} />
            </OptionImgGroup>
          </ImageArea>
          <OptionArea>
              <Suspense fallback={<OptionTextN
                  portrait={props?.portrait}
                  text={ props.clickable ? `${props?.text}` : `${text}` }
                />}>
              <OptionText
                portrait={props?.portrait}
                text={ props.clickable ? `${props?.text}` : `${text}` }
              />
           </Suspense>
            <MutiOptionGroup
              clickable={props?.clickable}
              title={props?.title}
              href={props?.href}
              params={props?.params}
            >
              {children}
            </MutiOptionGroup>
          </OptionArea>
      </Wrapper>
    )
  }
  
}

export default OptionWrapper
