import React, { useEffect } from "react"
import LargeTitleBtn from "../components/LargeTitleBtn"
import { useHistory } from "react-router-dom";

const IndexPage = () => {
  const history = useHistory()

  window.onload = function () {
    sessionStorage.clear();
    history.push({ pathname: '' })
  }

  useEffect(() => {
    if(window.location.href.includes('return')){
			document.cookie=`returnURL=${window.location.href.split('return=')[1]}; path=/`;
		}else if(!document.cookie.includes('return')){
      document.cookie="returnURL=https://ebank.firstbank.com.tw/FCBMEL/CSController?cmd=MEL000_1&s=S; path=/";
    }
  })
  
  return(
  <LargeTitleBtn
    title={`模擬創業，體驗人生`}
    text={`你的創業可以很不一樣，來看看自己具備什麼創業潛能?`}
    btnTitle={`開始遊戲`}
    href={`/work`}
  />
  )
}

export default IndexPage
