import { Images } from '../../Themes'

const works = [
    {
      portrait: `${Images.avatar_boss_gym}`, 
      img: `${Images.logo_gym}`,
    },
    {
      portrait: `${Images.avatar_boss_cafe}`,
      img: `${Images.logo_cafe}`
    },
    {
      portrait: `${Images.avatar_boss_clothes}`,
      img: `${Images.logo_clothes}`,
    },
]

export default works;