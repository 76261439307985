import React from 'react'
import styled from 'styled-components'
import { Colors, Images, Metrics, Fonts } from '../../Themes'

import { INext } from '../../types/index';
// import useSound from 'use-sound';

const NextButton = styled.div`
    margin-left: 20px;
    display: flex;
    width: 100px;
    margin-bottom: 30px;
    padding: 0;
    &:hover{
        cursor: pointer;
    }
    @media (max-width: ${Metrics.tablet}){
        max-width: 85px;
        margin: 0 auto;
        //margin-top: 5px;
    }
    
`

const Icon = styled.img`
    margin-left: 10px;
    @media (max-width: ${Metrics.tablet}){
        width: 24px;
    }
`

const Text = styled(Fonts.p())`
    color: ${Colors.BtnGreen};
`


const Next = (props: INext) => {
    // const [play] = useSound(props.sound)
    return (
        <NextButton>
            <Text>下一步</Text>
            <Icon src={`${Images.ic_arrow}`}/>
        </NextButton>
    )
}

export default Next