import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import { checkGameStatus } from "../utility"
import styled from 'styled-components'

import { Images, Styles, Audios } from '../Themes'

import Logo from "../components/Logo"
import WrapperTransition from "../components/WrapperTransition"

import { IProps, ITransitions } from "../types"

import works from '../Option/All/works'

// let audio = new Audio(`${Audios.build}`);

const Wrapper = styled(Styles.wrapper())``

const options: ITransitions = {
  A: [{
    portrait: ``,
    img: `${Images.blueprint}`, 
    href: '/built/A', 
    text: '想要按照自己的夢想藍圖打造帶有個人風格的店，又怕成本太高；但裝潢亮麗的店好像較能吸引到人，要盤算怎麼從成本與裝潢之間做取捨。',
  }],
  B: [{
    portrait: ``,
    img: `${Images.blueprint}`, 
    href: '/built/B',
    text: '想要按照自己的夢想藍圖打造帶有個人風格的店，又怕成本太高；但裝潢亮麗的店好像較能吸引到人，要盤算怎麼從成本與裝潢之間做取捨。',
  }],
  C: [{
    portrait: ``,
    img: `${Images.blueprint}`, 
    href: '/built/C',
    text: '想要按照自己的夢想藍圖打造帶有個人風格的店，又怕成本太高；但裝潢亮麗的店好像較能吸引到人，要盤算怎麼從成本與裝潢之間做取捨。',
  }],
}

const BuildingPage = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const [portrait, setPortrait] = useState('')
  const id = props.match?.params?.id;
  // const [playBgm, setPlayBgm] = useState(true)

  useEffect(() => {
    if(state){
      setPortrait(works[state.work].portrait)
    }
  }, [])

  // if(!playBgm){
  //   audio.muted = true;
  //   audio.pause()
  // }else{
  //   audio.muted = false;
  //   audio.play()
  //   audio.loop = true;
  // }
  
  // history.listen(() => { setPlayBgm(false); audio.muted = true;});

  if (!options[id]) {
    return <Redirect to="/error" />
  }

  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  
  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-5)} />
        {options[id].map((i) => (
          <WrapperTransition
            key={`Building-${id}`}
            portrait={portrait}
            img={i.img}
            text={i.text}
            href={i.href}
          />
      ))}
    </Wrapper>
  )
}

export default BuildingPage
