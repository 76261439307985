import React from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import Button from "../Button/Button"
import { IOptionArea } from '../../types/index';
import {  Metrics, Fonts, Styles } from '../../Themes'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled(Styles.OptionGroupWrapper())`
`
const Card = styled(Styles.OptionGroupCard())`
`
const TitleArea = styled(Styles.OptionGroupTitleArea())`
`
const Title = styled(Styles.OptionGroupTitle())`
`
const OptionGroup = styled(Styles.OptionGroupArea())`
    
    @media (max-width: ${Metrics.tablet}){
        .slick-slide{
            max-width: 80px; 
        }
    }
`
const ButtonArea = styled.div`
    justify-self: end;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
        min-width: 85px
    }
`

const OptionText = ({children, ...props}:IOptionArea) => {
  const history = useHistory();
//   const [play] = useSound(clickSound)
  return (
    <Wrapper>
        <Card>
            <TitleArea>
                <Title>{props?.title}</Title>
                <ButtonArea>
                <div
                    onClick={() => {
                        if (props.clickable) {
                        if (history.location.state) {
                            const { work, store, employee, money, path, successCount, skillA, skillB, skillC, skillD, skillE } = history.location.state as any;
                            history.push({ pathname: `${props.href}`, state:{
                                work: work || props.params?.work || '',
                                store: store || props.params?.store || '',
                                employee: employee || props.params?.employee || '',
                                money: money || props.params?.money || '',
                                path: path || props.params?.path || '',
                                successCount: successCount ? successCount + props.params?.successCount : props.params?.successCount,
                                skillA: skillA ? skillA + props.params?.skillA : props.params?.skillA,
                                skillB: skillB ? skillB + props.params?.skillB : props.params?.skillB,
                                skillC: skillC ? skillC + props.params?.skillC : props.params?.skillC,
                                skillD: skillD ? skillD + props.params?.skillD : props.params?.skillD,
                                skillE: skillE ? skillE + props.params?.skillE : props.params?.skillE,
                            }});
                            // play()
                        } else {
                            history.push({ pathname: `${props.href}`, state:{
                                work: props.params?.work || '',
                                store: props.params?.store || '',
                                employee: props.params?.employee || '',
                                money: props.params?.money || '',
                                path: props.params?.path || '',
                                successCount: props.params?.successCount || 0,
                                skillA: props.params?.skillA || 0,
                                skillB: props.params?.skillB || 0,
                                skillC: props.params?.skillC || 0,
                                skillD: props.params?.skillD || 0,
                                skillE: props.params?.skillE || 0,
                            }});
                            // play()
                            
                        }
                        } else {
                        alert('請至少選擇一個項目')
                        }
                    }
                    }
                    style={{ margin: `0 auto` }}
                >
                        <Button
                            clickable={props.clickable}
                            btnTitle='確認' 
                        />
                    </div>
                </ButtonArea>
            </TitleArea>
            <OptionGroup>
                <Slider
                    arrows={false}
                    infinite={false}
                    slidesToShow={3}
                    slidesToScroll={1}
                    responsive= {[
                        {
                            breakpoint: 960,
                            settings: {
                              slidesToShow: 2,
                            }
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 3,
                          }
                        }
                      ]}
                >
                    {children}
                </Slider>
            </OptionGroup>
        </Card>
    </Wrapper>
  )
}

export default OptionText
