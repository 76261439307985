import { Images } from '../Themes'
import { IOptionTwo } from '../types/index'

const options: IOptionTwo = {
  A: [
    { portrait: `${Images.avatar_boss_gym}`,portrait_2:`${Images.avatar_gym_01}`,icon:`${Images.employee_pt}`,img: `${Images.gym_pt}`, firstImg:`${Images.boss_gym_think}`, href: '/EmployeeTransitions/A', btnTitle: '工讀生', text: '剛開始的預算要花在刀口上，先讓月薪 22 K 的工讀生試看看吧。', text_2:`你好，我對健身領域很有熱情，想要試試看這份工作。`,clickable: true,
      params: {
        work: '', store:``, employee:`0`, money:``, 
        path: 'F',
        successCount: 1,
        skillA: 1,
        skillB: 1,
        skillC: 2,
        skillD: 1, 
        skillE: 0
      }
    },
    { portrait: `${Images.avatar_boss_gym}`,portrait_2:`${Images.avatar_gym_02}`,icon:`${Images.employee_fulltime}`,img: `${Images.gym_fulltime}`, firstImg:`${Images.boss_gym_think}`, href: '/EmployeeTransitions/A', btnTitle: '正職員工', text: '看起來非常負責任，月薪 40K 我應該可以放心處理公司其他事情。', text_2:`我有三年的健身教學經驗，也考取相關證照，大小事都難不倒我。`, clickable: true,
      params: {
        work: '', store:``, employee:`1`, money:``, 
        path: 'F',
        successCount: 0,
        skillA: 1,
        skillB: 1,
        skillC: 2,
        skillD: 1,
        skillE: 0
      }
    },
    { portrait: `${Images.avatar_boss_gym}`,portrait_2:`${Images.avatar_gym_03}`,icon:`${Images.employee_master}`,img: `${Images.gym_master}`, firstImg:`${Images.boss_gym_think}`, href: '/EmployeeTransitions/A', btnTitle: '專業教練', text: '月薪 10 萬是一筆不小的開銷，但是這位教練看起來非常專業！', text_2:`我擁有多張專業的證照及多年比賽的經驗，可以帶來高品質的服務。`, clickable: true,
      params: {
        work: '', store:``, employee:`2`, money:``, 
        path: 'F',
        successCount: 1,
        skillA: 0,
        skillB: -1,
        skillC: 0,
        skillD: 1,
        skillE: 0
      }
    },
  ],
  B: [
    { portrait: `${Images.avatar_boss_cafe}`,portrait_2:`${Images.avatar_cafe_01}`,icon:`${Images.employee_pt}`,img: `${Images.cafe_pt}`, firstImg:`${Images.boss_cafe_think}`, href: '/EmployeeTransitions/B', btnTitle: '工讀生', text: '看起來是可以培養的人才，但才剛開店，預算限制只能給出月薪 22 K。', text_2:`你好，我平常有自己烹煮咖啡的習慣，很想要試試看這份工作。`, clickable: true,
      params: {
        work: '', store:``, employee:`3`, money:``, 
        path: 'G',
        successCount: 1,
        skillA: 1,
        skillB: 1,
        skillC: 2,
        skillD: 1,
        skillE: 0
      }
    },
    { portrait: `${Images.avatar_boss_cafe}`,portrait_2:`${Images.avatar_cafe_02}`,icon:`${Images.employee_fulltime}`,img: `${Images.cafe_fulltime}`, firstImg:`${Images.boss_cafe_think}`, href: '/EmployeeTransitions/B', btnTitle: '正職員工', text: '看來是有點實戰經驗的人，月薪 40K 我應該可以放心處理公司其他事情。', text_2:`大學是咖啡社的，也有相關經驗，對烹煮及相關知識有一定的了解。`, clickable: true,
      params: {
        work: '', store:``, employee:`4`, money:``, 
        path: 'G',
        successCount: 1,
        skillA: 1,
        skillB: 1,
        skillC: 2,          
        skillD: 1,
        skillE: 0
      }
    },
    { portrait: `${Images.avatar_boss_cafe}`,portrait_2:`${Images.avatar_cafe_03}`,icon:`${Images.employee_master}`,img: `${Images.cafe_master}`, firstImg:`${Images.boss_cafe_think}`, href: '/EmployeeTransitions/B', btnTitle: '咖啡高手', text: '月薪 10 萬是一筆不小的開銷，但為了顧客的咖啡品質還算值得！', text_2:`我長期鑽研各種烹煮技巧與知識，曾經多次獲得世界咖啡師大賽冠軍。`, clickable: true,
      params: {
        work: '', store:``, employee:`5`, money:``, 
        path: 'G',
        successCount: 0,
        skillA: 0,
        skillB: -1,
        skillC: 2,
        skillD: 1,
        skillE: 0
      }
    },
  ],
  C: [
    { portrait: `${Images.avatar_boss_clothes}`,portrait_2:`${Images.avatar_clothes_01}`,icon:`${Images.employee_pt}`,img: `${Images.clothes_pt}`, firstImg:`${Images.boss_clothes_think}`, href: '/EmployeeTransitions/C', btnTitle: '工讀生', text: '剛開店，還沒整頓完全，先讓月薪 22 K 的工讀生試看看吧。', text_2:`你好，我有在服飾店打工的經驗，想要試試看這份工作。`, clickable: true,
      params: {
        work: '', store:``, employee:`6`, money:``,  
        path: 'H',
        successCount: 0,
        skillA: 1,
        skillB: 1,
        skillC: 2,
        skillD: 1,
        skillE: 0
      }
    },
    { portrait: `${Images.avatar_boss_clothes}`,portrait_2:`${Images.avatar_clothes_02}`,icon:`${Images.employee_fulltime}`,img: `${Images.clothes_fulltime}`, firstImg:`${Images.boss_clothes_think}`, href: '/EmployeeTransitions/C', btnTitle: '正職員工', text: '看起來有一定的能力，月薪 40K 我應該可以放心處理公司其他事情。', text_2:`我有一定的人脈與行銷知識，銷售大小事都難不倒我。`, clickable: true,
      params: {
        work: '', store:``, employee:`7`, money:``, 
        path: 'H',
        successCount: 1,
        skillA: 1,
        skillB: 1,
        skillC: 2,
        skillD: 0,
        skillE: 0
      }
    },
    { portrait: `${Images.avatar_boss_clothes}`,portrait_2:`${Images.avatar_clothes_03}`,icon:`${Images.employee_master}`,img: `${Images.clothes_master}`, firstImg:`${Images.boss_clothes_think}`, href: '/EmployeeTransitions/C', btnTitle: '銷售專員', text: '具有一定的名氣又有銷售經驗，平常又有注重穿搭，月薪 10 萬值得！', text_2:`我有在經營個人部落格，且有大量粉絲，除了會在社群媒體展現穿搭技巧，也有相關的銷售經驗。`, clickable: true,
      params: {
        work: '', store:``, employee:`8`, money:``,
        path: 'H',
        successCount: 1,
        skillA: 0,
        skillB: -1,
        skillC: 0,
        skillD: 0,
        skillE: 0
      }
    },
  ],
}

export default options;