import React, {useState, useEffect, Suspense, lazy} from 'react'
import styled from 'styled-components'
import { Metrics, Styles, Images  } from '../Themes'
import { isIE } from 'react-device-detect';
import OptionTextIE from "./Option/OptionTextIE"
import OptionTextN from "./Option/OptionTextNull"
import Next from "../components/Button/Next"
import { useHistory } from "react-router-dom";
import { ITransitionItem } from '../types/index';

const Wrapper = styled(Styles.TransitionWrapper())`
  @media (max-width: ${Metrics.tablet}){
    top: 15%;
  }
`
const ImageArea = styled(Styles.TransitionImgArea())`
  height: 50vh;
  margin-top: 60px;
  @media (max-width: ${Metrics.tablet}){
    margin-top: 0;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    height: 50vh;
  }
`
const Bg = styled(Styles.TransitionBg())`

`
const Img = styled(Styles.TransitionImg())`
  @media (max-width: ${Metrics.tablet}){
    max-width: 350px;
    height: 80%;
  }
`
const OptionArea = styled(Styles.TransitionOptionArea())`
  height: 40vh;
  .next{
    align-self: end
  }
  @media (max-width: ${Metrics.tablet}){
    max-height: 200px;
    .next{
      align-self: start
    }
  }
`

const TransitionWrapper = ( props: ITransitionItem) => {
  const history = useHistory();
  const [ text, setText ] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setText(`${props?.text}`)
        ;}, 1000)
    })
  if(isIE){
    const OptionText = lazy(() => import("./Option/OptionTextIE"));
    return (
      <Wrapper>
        <ImageArea>
          <Bg src={`${Images.bg}`} className='animate__flash animate__animated animate__slow animate__delay-1s' />
          <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s animate__faster' />
        </ImageArea>
        <OptionArea>
            <Suspense fallback={<OptionTextN portrait={props?.portrait} text={text}/>}>
              <OptionText
                portrait={props?.portrait}
                text={text}
              />
            </Suspense>
          <div onClick={() => history.location.state 
              ? history.push({ pathname: `${props.href}`, state: history.location.state }) 
              : history.push({ pathname: `${props.href}`})}
            className='next'
          >
            <Next />
          </div>
        </OptionArea>
      </Wrapper>
    )
  }else{
    const OptionText = lazy(() => import("./Option/OptionText"));
    return (
      <Wrapper>
        <ImageArea>
          <Bg src={`${Images.bg}`} className='animate__flash animate__animated animate__slow animate__delay-1s' />
          <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s animate__faster' />
        </ImageArea>
        <OptionArea>
            <Suspense fallback={<OptionTextN portrait={props?.portrait} text={text}/>}>
              <OptionText
                portrait={props?.portrait}
                text={text}
              />
            </Suspense>
          <div onClick={() => history.location.state 
              ? history.push({ pathname: `${props.href}`, state: history.location.state }) 
              : history.push({ pathname: `${props.href}`})}
            className='next'
          >
            <Next />
          </div>
        </OptionArea>
      </Wrapper>
    )
  }
  
}

export default TransitionWrapper
