import { ISkill } from '../types/index';
import * as R from 'ramda';

export function checkGameStatus(path: string): boolean {
  return ['F', 'G', 'H'].indexOf(path) === -1
}

const CASE1 = ['A', 'D', 'AE', 'AD', 'BE','CE', 'DE', 'ABC','ABD', 'ACE', 'ADE', 'BCD', 'BDE', 'CDE', 'ABCD', 'ABCE', 'ACDE', 'BCDE'] // 經驗開放性
const CASE2 = ['C', 'AC', 'BC', 'CD', 'ACD', 'BCE'] // 盡責性
const CASE3 = ['B', 'E', 'AB', 'BD', 'ABE', 'BDE'] // 外向性   
const CASE4 = ['ABCDE'] // 均衡性

export function getPersonility(list: Array<ISkill>) {
  const key = getKey(list)
  // console.log('key',key)
  if (CASE1.indexOf(key) !== -1) return ['極具眼界的','經驗開放型創業家','經驗開放型的創業家通常擁有良好的判斷及觀察力，能成功訂出策略外，腦中靈感也會不段湧現。']
  if (CASE2.indexOf(key) !== -1) return ['極具抗壓性的','盡責型創業家','盡責型的創業家通常擁有良好的耐心及觀察力配合著自身的判斷力，伺機而動，迎來成功。']
  if (CASE3.indexOf(key) !== -1) return ['極具動力的','外向型創業家','外向型創業家通常具備不錯的膽量及創造力天賦具有成長快速的淺能，也有一定的前瞻性。']
  if (CASE4.indexOf(key) !== -1) return ['極具潛能的','均能型創業家','均能型的創業家通常擁有無比的潛能除了極具商業頭腦外，更集創意於一身。']
  return '無對應'
}


const toNumber = R.pipe(R.head, R.applyTo(R.__, Number))

const findKey = R.pipe(
  R.mapObjIndexed(R.prop('length')),
  R.toPairs,
  R.sortBy(toNumber),
  R.last,
  R.head,
)

const combineKey = R.pipe(
  R.prop,
  R.reduce((acc: string, val: ISkill) => acc + val.id, '')
)
const getGroup = R.groupBy(R.prop('point'))
const getKey = R.pipe(
  getGroup,
  R.converge(combineKey, [findKey, R.identity])
)