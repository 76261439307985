import React, { useState } from "react"
import { Redirect, useHistory } from "react-router-dom";

import { Styles, Audios } from '../Themes'
import styled from 'styled-components'

import Logo from "../components/Logo"
import WrapperTransition from "../components/WrapperTransition"
import Skip from "../components/Button/Skip"

import { IProps } from '../types/index';
import { checkGameStatus } from "../utility";

import options from '../Option/halfYear'
import promotions from '../Option/promotionOptions'

// let audio = new Audio(`${Audios.year}`);

const Wrapper = styled(Styles.wrapper())``

const HalfYear = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  // const [playBgm, setPlayBgm] = useState(true)
  
  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 1200);
  //     setPlayBgm(false)
  // }

  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }

  return (
  <Wrapper>
    <Logo onClick={()=>history.go(-11)}/>
    <Skip params={promotions[`${id}SkipTrans`][0].params} />
    {options[id].map((i) => (
        <WrapperTransition
          key={`halfTear-${id}`}
          portrait={i.portrait}
          img={i.img}
          text={i.text}
          href={i.href}
        />
    ))}
  </Wrapper>
)}

export default HalfYear
