import { Images } from '../../Themes'

const stores = [
  {
    img: `${Images.house_small}`,
  },
  {
    img: `${Images.house_medium}`
  },
  {
    img: `${Images.house_big}`,
  },
]

export default stores;