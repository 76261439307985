export const Colors = {
    G10: '#E0E0E1',
    G30: '#9DA1A4',
    G50: '#3A3E41' ,
    G60: '#252A2D' ,
    G80: '#1A1F22',
    W5: 'rgba(255,255,255,0.05)',
    W10: 'rgba(255,255,255,0.1)',
    W20: 'rgba(255, 255, 255, 0.2)',
    W50: 'rgba(255, 255, 255, 0.5)',
    W80: 'rgba(255, 255, 255, 0.8)',
    W100: 'rgba(255, 255, 255, 1)',
    B100: 'rgba(0,0,0,1)',
    B80: 'rgba(0,0,0,0.8)',
    B50: 'rgba(0,0,0,0.5)',
    B30: 'rgba(0,0,0,0.3)',
    B10: 'rgba(0,0,0,0.1)',

    BackGround: '#FAFAFA',
    BtnGreen: '#5FC68B',

    TitleBlack: '#111314',
    SubTitleGray: '#A3A5A6'
  };

export default Colors;
  
