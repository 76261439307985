import React, { useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom";

import { Styles, Images, Audios } from '../Themes'
import styled from 'styled-components'

import Logo from "../components/Logo"
import WrapperTransition from "../components/WrapperTransition"
import Skip from "../components/Button/Skip"

import { IProps } from '../types/index';
import { checkGameStatus } from "../utility";

import works from '../Option/All/works'
import strength from '../Option/strengthOption'

// let audio = new Audio(`${Audios.year}`);

const Wrapper = styled(Styles.wrapper())`
  .none{
    display: none;
  }
`

const OneYear = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const [portrait, setPortrait] = useState('')
  // const [playBgm, setPlayBgm] = useState(true)

  useEffect(() => {
    if(state.work){
      setPortrait(works[state.work].portrait)
    }
  }, [])

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 1200);
  //     setPlayBgm(false)
  // }
  
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }

  return (
    <Wrapper>
      <Logo  onClick={()=>history.go(-16)}/>
      <Skip params={{
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0,
        }} />
        <WrapperTransition
          portrait={portrait}
          img={`${Images.calendar_02}`}
          text={`創業滿一年，來檢視自己創業成果吧！`}
          href={state?.successCount === 0 ? '/success' : '/failed'}
        />
    </Wrapper>
  )
}

export default OneYear
