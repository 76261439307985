import React,{ useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import { checkGameStatus } from "../utility"
import styled from 'styled-components'

import { Images, Styles, Audios } from '../Themes'

import Logo from "../components/Logo"
import WrapperTwoTransition from "../components/WrapperTwoTransition"

import { IProps } from "../types"
import { ITransitionsTwo } from '../types/index';

import employees from '../Option/All/employees'

// let audio = new Audio(`${Audios.speak}`);

const Wrapper = styled(Styles.wrapper())``

const options: ITransitionsTwo = {
  A: [{
    portrait: `${Images.avatar_boss_gym}`,
    text: '歡迎加入！很高興能跟你共事！希望能在未來的日子裡，一起打拼。',
    portrait_2: `${Images.avatar_gym_01}`,
    text_2: `沒問題，我會全力以赴！`,
    img: ``, 
    href: '/promotion/A', 
  }],
  B: [{
    portrait: `${Images.avatar_boss_cafe}`,
    portrait_2: `${Images.avatar_cafe_01}`,
    text: '歡迎加入！很高興能跟你共事！希望能在未來的日子裡，一起打拼。',
    text_2: `沒問題，我會全力以赴！`,
    img: ``, 
    href: '/promotion/B',
  }],
  C: [{
    portrait: `${Images.avatar_boss_clothes}`,
    portrait_2: `${Images.avatar_clothes_01}`,
    text: '歡迎加入！很高興能跟你共事！希望能在未來的日子裡，一起打拼。',
    text_2: `沒問題，我會全力以赴！`,
    img: ``, 
    href: '/promotion/C',
  }],
}

const EmployeeTransitionsPage = (props: IProps) => {

  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  const [portrait_2, setPortrait_2] = useState('')
  const [img, setImg] = useState('')
  // const [playBgm, setPlayBgm] = useState(true)

  useEffect(() => {
    if(state.employee){
      setPortrait_2(employees[state.employee].portrait)
      setImg(employees[state.employee].img)
    }
  },[])

  // if(!playBgm){
  //   audio.muted = true;
  // }else{
  //   audio.muted = false;
  //   audio.play()
  //   audio.loop = true;
  // }
  
  // history.listen(() => { setPlayBgm(false);audio.muted = true; });

  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  
  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-8)}/>
        {options[id].map((i) => (
          <WrapperTwoTransition
            key={`emplyeeTransition-${id}`}
            portrait={i.portrait}
            portrait_2={portrait_2}
            img={img}
            text={i.text}
            text_2={i.text_2}
            href={i.href}
          />
        ))}
    </Wrapper>
  )
}

export default EmployeeTransitionsPage
