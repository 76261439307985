import React, { useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom";

import { Styles, Images, Audios } from '../Themes'
import styled from 'styled-components'

import Logo from "../components/Logo"
import WrapperTransition from "../components/WrapperTransition"

import { IProps } from '../types/index';
import { checkGameStatus, getPersonility } from "../utility"

import works from '../Option/All/works'

// let audio = new Audio(`${Audios.success}`);

const Wrapper = styled(Styles.wrapper())``

const Success = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const [portrait, setPortrait] = useState('')
  const [ result, setResult ] = useState('')
  // const [playBgm, setPlayBgm] = useState(true)

  const personility = getPersonility([
    { id: 'A', point: state?.skillA || 0 },
    { id: 'B', point: state?.skillB || 0 },
    { id: 'C', point: state?.skillC || 0 },
    { id: 'D', point: state?.skillD || 0 },
    { id: 'E', point: state?.skillE || 0 },
  ])

  useEffect(() => {
    if(state.work){
      setPortrait(works[state.work].portrait)
    }
    if(personility[0].includes('眼界')){
      setResult('A')
    }else if(personility[0].includes('抗壓')){
      setResult('B')
    }else if(personility[0].includes('動力')){
      setResult('C')
    }else if(personility[0].includes('潛能')){
      setResult('D')
    }
  }, [])

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 1200);
  //     setPlayBgm(false)
  // }

  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }

  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-17)}/>
        <WrapperTransition
          portrait={portrait}
          img={`${Images.taiwan_01}`}
          text={`因為你的努力不懈，成功掀起熱潮，成為同業可敬的對手！`}
          href={`/summary/${result}`}
        />
    </Wrapper>
  )
}

export default Success
