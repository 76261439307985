import React,{ useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import styled from 'styled-components'
import Option from '../components/Option'
import Logo from "../components/Logo"
import OptionWrapper from "../components/OptionWrapper"
import { Images, Styles } from '../Themes'
import { IProps } from '../types/index'
import { checkGameStatus } from '../utility'
import options from '../Option/storeOptions'

import house_big from '../img/main/house_big.png'
import house_small from '../img/main/house_small.png'
import house_medium from '../img/main/house_medium.png'

// let audio = new Audio(`${Audios.bo}`);

const Wrapper = styled(Styles.wrapper())``

const OptionDiv = styled.div`
  margin: 0 auto;
  outline: none;
`

const StorePage = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;

  const [selected, setSelected] = useState('')
  const [portrait, setPortrait] = useState('')
  const [text, setText] = useState('')
  const [img, setImg] = useState('')
  const [href, setHref] = useState('')
  const [params, setParams] = useState()
  const [clickable, setClickable] = useState(false)
  const imgList =[house_small,house_medium,house_big]
  // const [playBgm, setPlayBgm] = useState(true)

    useEffect(() => {
      setImg(`${Images.house_empty}`)
      setText('店面位置很關鍵，要一開始就大店面，還是保守一點好呢?')
      setPortrait(`${options[id][0].portrait}`)
      setHref('')

      imgList.forEach((image) => {
        new Image().src = image
      });

      for (let i = 0; i < 3 ; i++) {
        if( selected === `${i}`){
          setImg(imgList[i])
          setText(options[id][i].text)
          setPortrait(options[id][i].portrait)
          setHref(options[id][i].href)
          setClickable(options[id][i].clickable)
          setParams(options[id][i].params)
        }
      }
    })

    // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
    //   audio.muted = true;
    // }
    // if(!sessionStorage.getItem('refreshed') && playBgm){
    //   setTimeout(function() {
    //     audio.muted = false;
    //     audio.play()
    //   }, 1000);
    //   setPlayBgm(false)
    // }

    if (!options[id]) {
      return <Redirect to="/error" />
    }
    if (checkGameStatus(state?.path || 'noPath')) {
      return <Redirect to="/" />
    }

  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-4)} />
      <OptionWrapper 
        className='animate__fadeIn animate__animated animate__delay-1s animate__faster'
        className_2='animate__bounceInDown animate__animated animate__faster'
        title='請選擇店面規模'
        img={img}
        portrait={`${options[id][0].portrait}`}
        text={text}
        href={href}
        clickable={clickable}
        params={params}
      >
        {options[id].map((i, index) => (
          <OptionDiv key={`work-${index}`} id={`${index}`} onClick={() =>setSelected(`${index}`)}>
            <Option
              img={i.icon}
              btnTitle={i.btnTitle}
              text={i.text}
              id={`store_${index}`}
              for={`store_${index}`}
            />
          </OptionDiv>
        ))}
      </OptionWrapper>
    </Wrapper>
  )
}
export default StorePage
