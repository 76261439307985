import React,{ useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import styled from 'styled-components'
import Option from "../components/Option"
import Logo from "../components/Logo"
import OptionTwoWrapper from "../components/OptionTwoWrapper"
import { Styles, Audios } from '../Themes'
import { IProps } from '../types/index'
import { checkGameStatus } from "../utility"
import options from '../Option/employeeOptions'

import gym_fulltime from '../img/main/gym_fulltime.png'
import gym_master from '../img/main/gym_master.png'
import gym_pt from '../img/main/gym_pt.png'
import cafe_fulltime from '../img/main/cafe_fulltime.png'
import cafe_master from '../img/main/cafe_master.png'
import cafe_pt from '../img/main/cafe_pt.png'
import clothes_fulltime from '../img/main/clothes_fulltime.png'
import clothes_master from '../img/main/clothes_master.png'
import clothes_pt from '../img/main/clothes_pt.png'

import avatar_gym_01 from '../img/avatar/gym_01.svg'
import avatar_gym_02 from '../img/avatar/gym_02.svg'
import avatar_gym_03 from '../img/avatar/gym_03.svg'
import avatar_cafe_01 from '../img/avatar/cafe_01.svg'
import avatar_cafe_02 from '../img/avatar/cafe_02.svg'
import avatar_cafe_03 from '../img/avatar/cafe_03.svg'
import avatar_clothes_01 from '../img/avatar/clothes_01.svg'
import avatar_clothes_02 from '../img/avatar/clothes_02.svg'
import avatar_clothes_03 from '../img/avatar/clothes_03.svg'

// let initAudio = new Audio(`${Audios.bo}`);

// const bgm = require('../audio/sound/speak/speak.mp3');
// const audio = new Audio(bgm)

const Wrapper = styled(Styles.wrapper())``

const OptionDiv = styled.div`
  margin: 0 auto;
  outline: none;
`

const Employee = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  const [selected, setSelected] = useState('')
  const [portrait, setPortrait] = useState('')
  const [text, setText] = useState('')
  const [portrait_2, setPortrait_2] = useState('')
  const [text_2, setText_2] = useState('')
  const [img, setImg] = useState('')
  const [href, setHref] = useState('')
  const [params,setParams] = useState()
  const [clickable, setClickable] = useState(false)
  const imgList = [
    [ gym_pt, gym_fulltime, gym_master ],
    [ cafe_pt, cafe_fulltime, cafe_master ],
    [ clothes_pt, clothes_fulltime, clothes_master ]
  ]
  const avatarList = [
    [ avatar_gym_01, avatar_gym_02, avatar_gym_03 ],
    [ avatar_cafe_01, avatar_cafe_02, avatar_cafe_03 ],
    [ avatar_clothes_01, avatar_clothes_02, avatar_clothes_03 ]
  ]
  // const [playBgm, setPlayBgm] = useState(true)
  
  useEffect(() => {
    setImg(`${options[id][0].firstImg}`)
    setText('收到好多履歷，哪些才是適合的人才呢！')
    setPortrait(`${options[id][0].portrait}`)

    if(state){
      imgList[state.work].forEach((image) => {
        new Image().src = image
      });

      avatarList[state.work].forEach((image) => {
        new Image().src = image
      });
  
      for (let i = 0; i < 3 ; i++) {
        if( selected === `${i}`){
          setImg(imgList[state.work][i])
          setText_2(options[id][i].text)
          setText(options[id][i].text_2)
          setPortrait_2(options[id][i].portrait)
          setPortrait(avatarList[state.work][i])
          setHref(options[id][i].href)
          setClickable(options[id][i].clickable)
          setParams(options[id][i].params)
        }
      }
    }
  })

  // if(!playBgm){
  //   initAudio.muted = true;
  // }
  // if( playBgm){
  //   setTimeout(function() {
  //     initAudio.muted = false;
  //     initAudio.play()
  //   }, 1000);
  //   setPlayBgm(false)
  // }

  // history.listen(() => { setSelected('');audio.muted= true; });
  history.listen(() => { setSelected(''); });

  // if( selected ){
  //   audio.play();
  //   audio.loop= true;
  //   audio.muted= false;
  // }else{
  //   audio.muted= true;
  // }

  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  return (
    <Wrapper>
      <Logo onClick={()=>history.go(-7)}/>
      <OptionTwoWrapper 
        className='animate__fadeIn animate__animated animate__delay-1s animate__faster'
        className_2='animate__fadeInRight animate__animated animate__faster'
        title='請選擇合作夥伴'
        img={img}
        portrait={portrait}
        text={text}
        portrait_2={portrait_2}
        text_2={text_2}
        href={href}
        clickable={clickable}
        params={params}
      >
        {options[id].map((i, index) => (
          <OptionDiv key={`${id}-emplyee-${index}`} id={`${index}`} onClick={() =>setSelected(`${index}`)}>
            <Option
              // clickable={i.clickable}
              img={i.icon}
              // href={i.href}
              btnTitle={i.btnTitle}
              text={i.text}
              // params={i.params}
              id={`emplyee_${index}`}
              for={`emplyee_${index}`}
            />
          </OptionDiv>
        ))}
      </OptionTwoWrapper>
    </Wrapper>
  )
}

export default Employee
