import { IMutiOption } from '../types/index';
import { Images } from '../Themes'

const options: IMutiOption = {
    A: [
      { img: `${Images.promote_01}`, icon:`${Images.youtube}`,btnTitle: 'Youtube',
        text: '大家每天都會看 YouTube，如果我也在 YouTube 上成立教學頻道，順便宣傳我的店，搞不好會很有成效。',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 1,
          skillE: 0,
        } 
      },
      { img: `${Images.promote_02}`, icon:`${Images.chatbot}` , btnTitle: 'Chatbot',
        text: '聊天機器人有一定的開發成本，但能隨時回覆顧客問題，感覺會比較親切，應該會是個好點子。',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: -1,
          skillE: 1,
        }
      },
      { img: `${Images.promote_03}`, icon:`${Images.discount}`, btnTitle: '優惠折扣',
        text: '提供優惠折扣雖然老套，但總是有效！',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: -1,
          skillD: 0,
          skillE: -1,
        }
      },
      { img: `${Images.promote_04}`, icon:`${Images.celebrity}` , btnTitle: '網紅代言',
        text: '近幾年很多健身網紅在網路上活躍，如果請他們合作與推薦的話，應該會具有一定的公信力。',
        params: {
          successCount: 1,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 1,
          skillE: -1,
        }
      },
      { img: `${Images.promote_05}`, icon:`${Images.facebook}` , btnTitle: '網路行銷',
        text: '感覺行銷一定缺不了在網路上買廣告，畢竟現在網路那麼方便。網路行銷的效益感覺會比傳統方式還高。',
        params: {
          successCount: 1,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      },
    ],
    B: [
      { img: `${Images.promote_01}`, icon:`${Images.youtube}`, btnTitle: 'Youtube',
        text: '大家每天都會看 YouTube，如果我和一些知名的 YouTuber 合作的話，勢必能透過 YouTuber 的介紹讓大家認識我吧。', 
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      },
      { img: `${Images.promote_02}`, icon:`${Images.chatbot}` , btnTitle: 'Chatbot',
        text: '聊天機器人有一定的開發成本，但能隨時回覆顧客問題，感覺會比較親切，應該會是個好點子。',
        params: {
          successCount: 1,
          skillA: -1,
          skillB: 0,
          skillC: 0,
          skillD: -1,
          skillE: 1,
        }
      },
      { img: `${Images.promote_03}`, icon:`${Images.discount}`, btnTitle: '優惠折扣',
        text: '提供優惠折扣雖然老套，但總是有效！',
        params: {
          successCount: 1,
          skillA: 1,
          skillB: 0,
          skillC: 0,
          skillD: 1,
          skillE: -1,
        }
      },
      { img: `${Images.promote_04}`, icon:`${Images.celebrity}`, btnTitle: '網紅代言',
        text: '近幾年很多特色裝潢的店面都會吸引網紅去體驗，如果我也請請網紅合作 ，說不定能提高能見度。',
        params: {
          successCount: 1,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 0,
          skillE: -1,
        }
      },
      { img: `${Images.promote_05}`, icon:`${Images.facebook}`, btnTitle: '網路行銷',
        text: '感覺行銷一定缺不了在網路上買廣告，畢竟現在網路那麼方便。網路行銷的效益感覺會比傳統方式還高。',
        params: {
          successCount: 0,
          skillA: 0,
          skillB: 1,
          skillC: 0,
          skillD: 1,
          skillE: 1,
        }
      }, 
    ],
    C: [
      { img: `${Images.promote_01}`, icon:`${Images.youtube}` , btnTitle: 'YouTube',
        text: '很多 YouTuber 會在網路上開箱或是教別人怎麼穿搭，或許透過 YouTuber 的合作，能讓大家認識我的店。',
        params: {
          successCount: 1,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 1,
        }
      },
      { img: `${Images.promote_02}`, icon:`${Images.chatbot}` , btnTitle: 'Chatbot',
        text: '聊天機器人有一定的開發成本，但能隨時回覆顧客問題，感覺會比較親切，應該會是個好點子。',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: -1,
          skillC: 1,
          skillD: 1,
          skillE: 1,
        }
      },
      { img: `${Images.promote_03}`, icon:`${Images.discount}`, btnTitle: '優惠折扣',
        text: '提供優惠折扣雖然老套，但總是有效！',
        params: {
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: 1,
          skillD: 1,
          skillE: 1,
        }
      },
      { img: `${Images.promote_04}`, icon:`${Images.celebrity}`, btnTitle: '藝人代言',
        text: '知名藝人擁有一定的人氣，如果請他們合作與推薦的話，應該會具有一定的公信力。',
        params: {
          successCount: 1,
          skillA: 0,
          skillB: -1,
          skillC: 0,
          skillD: 0,
          skillE: -1,
        }
      },
      { img: `${Images.promote_05}`, icon:`${Images.facebook}`, btnTitle: '網路行銷',
        text: '感覺行銷一定缺不了在網路上買廣告，畢竟現在網路那麼方便。網路行銷的效益感覺會比傳統方式還高。',
        params: {
          successCount: 1 ,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 1,
        }
      },
    ],
    ASkip: [{
      img: ``, icon:``,btnTitle: '',
      text: '',
      params: {
        successCount: 0,
        skillA: 1,
        skillB: 1,
        skillC: -1,
        skillD: -1,
        skillE: 1,
      },
    }],
    BSkip: [{
      img: ``, icon:``,btnTitle: '',
      text: '',
      params: {
        successCount: 0,
        skillA: 0,
        skillB: 1,
        skillC: 1,
        skillD: 2,
        skillE: 2,
      },
    }],
    CSkip: [{
      img: ``, icon:``,btnTitle: '',
      text: '',
      params: {
        successCount: 0,
        skillA: 2,
        skillB: 2,
        skillC: 1,
        skillD: 3,
        skillE: 2,
      },
    }],
    ASkipTrans: [{
      img: ``, icon:``,btnTitle: '',
      text: '',
      params: {
        successCount: 0,
        skillA: 1,
        skillB: 1,
        skillC: -1,
        skillD: 0,
        skillE: 0,
      },
    }],
    BSkipTrans: [{
      img: ``, icon:``,btnTitle: '',
      text: '',
      params: {
        successCount: 0,
        skillA: 0,
        skillB: 1,
        skillC: 1,
        skillD: 1,
        skillE: 1,
      },
    }],
    CSkipTrans: [{
      img: ``, icon:``,btnTitle: '',
      text: '',
      params: {
        successCount: 0,
        skillA: 1,
        skillB: 1,
        skillC: 0,
        skillD: 2,
        skillE: 1,
      },
    }],
  }

export default options;