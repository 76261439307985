import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import { checkGameStatus } from "../utility"
import styled from 'styled-components'

import { Images, Styles, Audios } from '../Themes'

import Logo from "../components/Logo"
import WrapperTransition from "../components/WrapperTransition"
import Skip from "../components/Button/Skip"

import { IProps } from "../types"
import { ITransitions } from '../types/index';

import money from '../Option/All/money'
import moneyOptions from '../Option/moneyOptions'

// let audio = new Audio(`${Audios.money}`);

const Wrapper = styled(Styles.wrapper())``

const options: ITransitions = {
  A: [{
    portrait: `${Images.avatar_boss_gym}`,
    img: ``, 
    href: '/strength/A', 
    text: '',
    
  }],
  B: [{
    portrait: `${Images.avatar_boss_cafe}`,
    img: ``,
    href: '/strength/B',
    text: '',
  }],
  C: [{
    portrait: `${Images.avatar_boss_clothes}`,
    img: ``, 
    href: '/strength/C',
    text: '',
  }],
}

const MoneyTransitionsPage = (props: IProps) => {

  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  const [img, setImg] = useState('')
  const [text, setText] = useState('')
  // const [playBgm, setPlayBgm] = useState(true)

  useEffect(() => {
    if(state.money){
      setImg(money[state.money].img)
      setText(money[state.money].text)
    }
  }, [])

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(!sessionStorage.getItem('refreshed') && playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 1200);
  //     setPlayBgm(false)
  // }
  
  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  
  return (
    <Wrapper>
        <Logo onClick={()=>history.go(-13)}/>
        <Skip params={moneyOptions[`${id}SkipTrans`][0].params} />
        {options[id].map((i) => (
          <WrapperTransition
            key={`moneyTransition-${id}`}
            portrait={i.portrait}
            img={img}
            text={text}
            href={i.href}
          />
        ))}
    </Wrapper>
  )
}


export default MoneyTransitionsPage
