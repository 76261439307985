import { IOptionTwo } from "../types"
import { Images } from '../Themes'

const options: IOptionTwo = {
    A: [
      {
        portrait: `${Images.avatar_boss_gym}`, portrait_2:`${Images.avatar_uncle}`, icon:`${Images.money_01}`, img: `${Images.bank_100_01}`, firstImg:`${Images.money_empty}`, href: '/strength/A', btnTitle: '親友借貸', text: '100萬的資金，以目前的狀況還算夠用。', text_2:`聽說你正在籌措創業資金，我借你 100 萬吧，祝你創業成功！`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`0`,
          path: 'F',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
      {
        portrait: `${Images.avatar_boss_gym}`, portrait_2:`${Images.avatar_bank}`, icon:`${Images.money_02}`, img: `${Images.bank_300_01}`, firstImg:``, href: '/strength/A', btnTitle: '銀行貸款', text: '300萬對日後的規劃來說充裕很多，我可以做很多的選擇。',  text_2:`您好，很高興為您服務，針對您的創業需求，向您建議中型貸款 300 萬。`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`1`,
          path: 'F',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
      {
        portrait: `${Images.avatar_boss_gym}`, portrait_2:`${Images.avatar_investor}`, icon:`${Images.money_03}`,img: `${Images.investor}`, firstImg:``, href: '/strength/A', btnTitle: '投資人', text: '投資人要求入股，要評估日後公司自主權可能會收到限制的風險。', text_2:`年輕人，你很有潛力，我決定投資你 300 萬＋入股 10%。`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'F',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
    ],
    B: [
      {
        portrait: `${Images.avatar_boss_cafe}`, portrait_2:`${Images.avatar_uncle}`, icon:`${Images.money_01}`,img: `${Images.bank_100_01}`, firstImg:`${Images.money_empty}`, href: '/strength/B', btnTitle: '親友借貸', text: '100萬的資金，以目前的狀況還算夠用。', text_2:`聽說你正在籌措創業資金，我借你 100 萬吧，祝你創業成功！`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`0`,
          path: 'G',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
      {
        portrait: `${Images.avatar_boss_cafe}`, portrait_2:`${Images.avatar_bank}`, icon:`${Images.money_02}`,img: `${Images.bank_300_01}`, firstImg:``, href: '/strength/B', btnTitle: '銀行貸款', text: '300萬對日後的規劃來說充裕很多，我可以做很多的選擇。', text_2:`您好，很高興為您服務，針對您的創業需求，向您建議中型貸款 300 萬。`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`1`,
          path: 'G',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
      {
        portrait: `${Images.avatar_boss_cafe}`, portrait_2:`${Images.avatar_investor}`, icon:`${Images.money_03}`,img: `${Images.investor}`, firstImg:``, href: '/strength/B', btnTitle: '投資人', text: '投資人要求入股，要評估日後公司自主權可能會收到限制的風險。', text_2:`年輕人，你很有潛力，我決定投資你 300 萬＋入股 10%。`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'G',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
    ],
    C: [
      {
        portrait: `${Images.avatar_boss_clothes}`, portrait_2:`${Images.avatar_uncle}`, icon:`${Images.money_01}`,img: `${Images.bank_100_01}`, firstImg:`${Images.money_empty}`, href: '/strength/C', btnTitle: '親友借貸', text: '100萬的資金，以目前的狀況還算夠用。', text_2:`聽說你正在籌措創業資金，我借你 100 萬吧，祝你創業成功！`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`0`,
          path: 'H',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
      {
        portrait: `${Images.avatar_boss_clothes}`, portrait_2:`${Images.avatar_bank}`, icon:`${Images.money_02}`,img: `${Images.bank_300_01}`, firstImg:``, href: '/strength/C', btnTitle: '銀行貸款', text: '300萬對日後的規劃來說充裕很多，我可以做很多的選擇。', text_2:`您好，很高興為您服務，針對您的創業需求，向您建議中型貸款 300 萬。`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`1`,
          path: 'H',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
      {
        portrait: `${Images.avatar_boss_clothes}`, portrait_2:`${Images.avatar_investor}`, icon:`${Images.money_03}`,img: `${Images.investor}`, firstImg:``, href: '/strength/C', btnTitle: '投資人', text: '投資人要求入股，要評估日後公司自主權可能會收到限制的風險。', text_2:`年輕人，你很有潛力，我決定投資你 300 萬＋入股 10%。`, clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'H',
          successCount: 0,
          skillA: 0,
          skillB: 0,
          skillC: 0,
          skillD: 0,
          skillE: 0
        }
      },
    ],
    ASkip: [
      {
        portrait: '', portrait_2: '', icon: '',img: '', firstImg: '', href: '', btnTitle: '', text: '', text_2: '', clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'F',
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: -1,
          skillD: 0,
          skillE: 0,
        }
      }
    ],
    BSkip: [
      {
        portrait: '', portrait_2: '', icon: '',img: '', firstImg: '', href: '', btnTitle: '', text: '', text_2: '', clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'G',
          successCount: 0,
          skillA: 0,
          skillB: 1,
          skillC: 1,
          skillD: 1,
          skillE: 1,
        }
      }
    ],
    CSkip: [
      {
        portrait: '', portrait_2: '', icon: '',img: '', firstImg: '', href: '', btnTitle: '', text: '', text_2: '', clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'H',
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 2,
          skillE: 1,
        }
      }
    ],
    ASkipTrans: [
      {
        portrait: '', portrait_2: '', icon: '',img: '', firstImg: '', href: '', btnTitle: '', text: '', text_2: '', clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'F',
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: -1,
          skillD: 0,
          skillE: 0,
        }
      }
    ],
    BSkipTrans: [
      {
        portrait: '', portrait_2: '', icon: '',img: '', firstImg: '', href: '', btnTitle: '', text: '', text_2: '', clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'G',
          successCount: 0,
          skillA: 0,
          skillB: 1,
          skillC: 1,
          skillD: 1,
          skillE: 1,
        }
      }
    ],
    CSkipTrans: [
      {
        portrait: '', portrait_2: '', icon: '',img: '', firstImg: '', href: '', btnTitle: '', text: '', text_2: '', clickable: true,
        params: {
          work: ``, store:``, employee:``, money:`2`,
          path: 'H',
          successCount: 0,
          skillA: 1,
          skillB: 1,
          skillC: 0,
          skillD: 2,
          skillE: 1,
        }
      }
    ],
}

export default options;