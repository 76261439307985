import React, { useState } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import { checkGameStatus } from "../utility"
import styled from 'styled-components'

import { Images, Styles, Audios } from '../Themes'

import Logo from "../components/Logo"
import WrapperTransition from "../components/WrapperTransition"

import { IProps } from "../types"
import { ITransitions } from '../types/index';

// let audio = new Audio(`${Audios.done}`);

const Wrapper = styled(Styles.wrapper())``

const options: ITransitions = {
  A: [{
    portrait: `${Images.avatar_boss_gym}`,
    img: `${Images.boss_gym}`, 
    href: '/store/A', 
    text: '要主打甚麼特色，才能提升能見度，吸引會員加入呢?',
  }],
  B: [{
    portrait: `${Images.avatar_boss_cafe}`,
    img: `${Images.boss_cafe}`, 
    href: '/store/B',
    text: '終於有機會開一家咖啡廳了，要怎麼提高能見度與吸引人潮都是要煩惱的事，希望能在給予顧客高品質的咖啡服務與成本控管之間取得平衡。',
  }],
  C: [{
    portrait: `${Images.avatar_boss_clothes}`,
    img: `${Images.boss_clothes}`, 
    href: '/store/C',
    text: '除了得想想哪裡比較多人逛街，還得想想顧客對服飾的喜好差異頗大，未來要銷售甚麼風格的服飾，才能有穩定的客源呢?',
  }],
}

const WorksTransitionsPage = (props: IProps) => {
  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  // const [playBgm, setPlayBgm] = useState(true)

  // if(sessionStorage.getItem('refreshed')!=null || !playBgm){
  //   audio.muted = true;
  // }
  // if(playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 0);
  //     setPlayBgm(false)
  // }

  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }

  return (
    <Wrapper>
        <Logo onClick={()=>history.go(-3)} />
        {options[id].map((i) => (
            <WrapperTransition
              key={`workTransition-${id}`}
              portrait={i.portrait}
              img={i.img}
              text={i.text}
              href={i.href}
            />
        ))}
    </Wrapper>
  )
}


export default WorksTransitionsPage
