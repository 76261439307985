import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import { checkGameStatus } from "../utility"
import styled from 'styled-components'

import { Styles, Audios } from '../Themes'

import Logo from "../components/Logo"
import WrapperTransition from "../components/WrapperTransition"

import { IProps } from "../types"
import { ITransitions } from '../types/index';

import works from '../Option/All/works'
import stores from '../Option/All/stores'

// let audio = new Audio(`${Audios.done}`);

const Wrapper = styled(Styles.wrapper())``

const options: ITransitions = {
  A: [{
    portrait: ``,
    img: ``, 
    href: '/employee/A', 
    text: '店面裝修完工，要開始招募員工了，希望能順利找到一起努力的好夥伴！',
  }],
  B: [{
    portrait: ``,
    img: ``, 
    href: '/employee/B',
    text: '店面裝修完工，要開始招募員工了，希望能順利找到一起努力的好夥伴！',
  }],
  C: [{
    portrait: ``,
    img: ``, 
    href: '/employee/C',
    text: '店面裝修完工，要開始招募員工了，希望能順利找到一起努力的好夥伴！',
  }],
}

const BuiltPage = (props: IProps) => {

  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  const [portrait, setPortrait] = useState('')
  const [img, setImg] = useState('')
  // const [playBgm, setPlayBgm] = useState(true)
  
  useEffect(() => {
    if(state){
      setPortrait(works[state.work].portrait)
      setImg(stores[state.store].img)
    }
  }, [])

  // if(!playBgm){
  //   audio.muted = true;
  // }
  // if(playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 0);
  //     setPlayBgm(false)
  // }
  
  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  
  return (
    <Wrapper>
        <Logo onClick={()=>history.go(-6)}/>
        {options[id].map((i) => (
            <WrapperTransition
              key={`Built-${id}`}
              portrait={portrait}
              img={img}
              text={i.text}
              href={i.href}
            />
        ))}
    </Wrapper>
  )
}


export default BuiltPage
