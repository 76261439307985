import React, { useState, useEffect } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import { checkGameStatus } from "../utility"
import styled from 'styled-components'

import { Images, Styles, Audios } from '../Themes'

import Logo from "../components/Logo"
import MutiWrapperTransition from "../components/MutiWrapperTransitionB"
import Skip from "../components/Button/Skip"

import { IProps } from "../types"
import { ITransitions } from '../types/index';

import stores from '../Option/All/stores'
import strength from '../Option/strengthOption'
import transparent from '../img/promote/transparent.png'

// let audio = new Audio(`${Audios.done}`);

const Wrapper = styled(Styles.wrapper())``

const options: ITransitions = {
  A: [{
    portrait: `${Images.avatar_boss_gym}`,
    img: ``, 
    href: '/oneyear', 
    text: '我一直堅持提供最好的服務給顧客，獲得資金後，將店面優化整修一番，看起來換然一新。',
  }],
  B: [{
    portrait: `${Images.avatar_boss_cafe}`,
    img: ``, 
    href: '/oneyear',
    text: '我一直堅持提供最好的服務給顧客，獲得資金後，將店面優化整修一番，看起來換然一新。',
  }],
  C: [{
    portrait: `${Images.avatar_boss_clothes}`,
    img: ``, 
    href: '/oneyear',
    text: '我一直堅持提供最好的服務給顧客，獲得資金後，將店面優化整修一番，看起來換然一新。',
  }],
}

const StrengthTransitionsPage = (props: IProps) => {

  const history = useHistory()
  const state = history.location.state as any
  const id = props.match?.params?.id;
  const [img, setImg] = useState('')
  const [mutiOptionImgA, setMutiOptionImgA] = useState('')
  const [mutiOptionImgB, setMutiOptionImgB] = useState('')
  const [mutiOptionImgC, setMutiOptionImgC] = useState('')
  const classList = ['build','app','service','route']
  // const [playBgm, setPlayBgm] = useState(true)
  useEffect(() => {
    if(state){
      setImg(stores[state.store].img)
    }
    setMutiOptionImgA('')
    if(state.MutiOptionImgB){
      setMutiOptionImgB(strength[id][state.MutiOptionImgB].img)
    }else{
      setMutiOptionImgB(transparent)
    }
    if((state.MutiOptionImgC) !== 'undefined'){
      setMutiOptionImgC(strength[id][state.MutiOptionImgC].img)
    }else{
      setMutiOptionImgC(transparent)
    }
  },[])
  
  // if(!playBgm){
  //   audio.muted = true;
  // }
  // if(playBgm){
  //   setTimeout(function() {
  //     audio.muted = false;
  //     audio.play()
  //   }, 0);
  //     setPlayBgm(false)
  // }
  
  if (!options[id]) {
    return <Redirect to="/error" />
  }
  if (checkGameStatus(state?.path || 'noPath')) {
    return <Redirect to="/" />
  }
  
  return (
    <Wrapper>
        <Logo onClick={()=>history.go(-15)}/>
        <Skip params={strength[`${id}SkipTrans`][0].params} />
        {options[id].map((i) => (
            <MutiWrapperTransition
              key={`StrentTransition-${id}`}
              portrait={i.portrait}
              img={img}
              text={i.text}
              href={i.href}
              mutiOptionImgA={mutiOptionImgA}
              mutiOptionImgB={mutiOptionImgB}
              mutiOptionImgC={mutiOptionImgC}
              imgA={`${classList[state.MutiOptionImgA]}`}
              imgB={`${classList[state.MutiOptionImgB]}`}
              imgC={`${classList[state.MutiOptionImgC]}`}
            />
        ))}
    </Wrapper>
  )
}


export default StrengthTransitionsPage
