const Images = {

    logo: require('../img/logo_etime.svg'),

    home: require('../img/home.png'),
    bg: require('../img/bg.png'),
    

    // avatar
    avatar_uncle: require('../img/avatar/uncle.svg'),
    avatar_bank: require('../img/avatar/bank.svg'),
    avatar_investor: require('../img/avatar/investor.svg'),
    avatar_boss_cafe: require('../img/avatar/boss_cafe.svg'),
    avatar_boss_clothes: require('../img/avatar/boss_clothes.svg'),
    avatar_boss_empty: require('../img/avatar/boss_empty.svg'),
    avatar_boss_gym: require('../img/avatar/boss_gym.svg'),
    avatar_cafe_01: require('../img/avatar/cafe_01.svg'),
    avatar_cafe_02: require('../img/avatar/cafe_02.svg'),
    avatar_cafe_03: require('../img/avatar/cafe_03.svg'),
    avatar_clothes_01: require('../img/avatar/clothes_01.svg'),
    avatar_clothes_02: require('../img/avatar/clothes_02.svg'),
    avatar_clothes_03: require('../img/avatar/clothes_03.svg'),
    avatar_gym_01: require('../img/avatar/gym_01.svg'),
    avatar_gym_02: require('../img/avatar/gym_02.svg'),
    avatar_gym_03: require('../img/avatar/gym_03.svg'),

    // body
    body_cafe_01: require('../img/body/cafe_01.png'),
    body_cafe_02: require('../img/body/cafe_02.png'),
    body_cafe_03: require('../img/body/cafe_03.png'),
    body_clothes_01: require('../img/body/clothes_01.png'),
    body_clothes_02: require('../img/body/clothes_02.png'),
    body_clothes_03: require('../img/body/clothes_03.png'),
    body_gym_01: require('../img/body/gym_01.png'),
    body_gym_02: require('../img/body/gym_02.png'),
    body_gym_03: require('../img/body/gym_03.png'),

    // button
    app: require('../img/button/app.png'),
    arrow: require('../img/button/arrow.png'),
    cafe: require('../img/button/cafe.png'),
    chair: require('../img/button/chair.png'),
    chatbot: require('../img/button/chatbot.png'),
    clothes: require('../img/button/clothes.png'),
    discount: require('../img/button/discount.png'),
    employee_pt: require('../img/button/pt.png'),
    employee_fulltime: require('../img/button/fulltime.png'),
    employee_master: require('../img/button/master.png'),
    etime: require('../img/button/etime.png'),
    facebook: require('../img/button/facebook.png'),
    house_01: require('../img/button/house_01.png'),
    house_02: require('../img/button/house_02.png'),
    house_03: require('../img/button/house_03.png'),
    button_cafe: require('../img/button/logo_cafe.png'),
    button_clothes: require('../img/button/logo_clothes.png'),
    button_gym: require('../img/button/logo_gym.png'),
    member: require('../img/button/member.png'),
    money_01: require('../img/button/money_01.png'),
    money_02: require('../img/button/money_02.png'),
    money_03: require('../img/button/money_03.png'),
    refresh: require('../img/button/refresh.png'),
    celebrity: require('../img/button/selebrity.png'),
    share: require('../img/button/share.png'),
    work: require('../img/button/work.png'),
    youtube: require('../img/button/youtube.png'),
    gym: require('../img/button/gym.png'),

    ic_arrow: require('../img/ic_arrow.svg'),
    ic_check: require('../img/ic_check.svg'),

    // main
    bank_100_01: require('../img/main/bank_100_01.png'),
    bank_100_02: require('../img/main/bank_100_02.png'),
    bank_300_01: require('../img/main/bank_300_01.png'),
    bank_300_02: require('../img/main/bank_300_02.png'),
    bank: require('../img/main/bank.png'),
    blueprint: require('../img/main/blueprint.png'),
    boss_cafe_think: require('../img/main/boss_cafe_think.png'),
    boss_cafe: require('../img/main/boss_cafe.png'),
    boss_clothes_think: require('../img/main/boss_clothes_think.png'),
    boss_clothes: require('../img/main/boss_clothes.png'),
    boss_empty: require('../img/main/boss_empty.png'),
    boss_gym_think: require('../img/main/boss_gym_think.png'),
    boss_gym: require('../img/main/boss_gym.png'),
    calendar_01: require('../img/main/calendar_01.png'),
    calendar_02: require('../img/main/calendar_02.png'),
    data_bg: require('../img/main/data.png'),
    yellow_bg: require('../img/main/yellow_bg.png'),
    cafe_fulltime: require('../img/main/cafe_fulltime.png'),
    cafe_master: require('../img/main/cafe_master.png'),
    cafe_pt: require('../img/main/cafe_pt.png'),
    clothes_fulltime: require('../img/main/clothes_fulltime.png'),
    clothes_master: require('../img/main/clothes_master.png'),
    clothes_pt: require('../img/main/clothes_pt.png'),
    gym_fulltime: require('../img/main/gym_fulltime.png'),
    gym_master: require('../img/main/gym_master.png'),
    gym_pt: require('../img/main/gym_pt.png'),
    house_big: require('../img/main/house_big.png'),
    house_empty: require('../img/main/house_empty.png'),
    house_medium: require('../img/main/house_medium.png'),
    house_small: require('../img/main/house_small.png'),
    investor: require('../img/main/investor.png'),
    logo_cafe: require('../img/main/logo_cafe.png'),
    logo_clothes: require('../img/main/logo_clothes.png'),
    logo_gym: require('../img/main/logo_gym.png'),
    money_empty: require('../img/main/money_empty.png'),
    taiwan_01: require('../img/main/taiwan_01.png'),
    taiwan_02: require('../img/main/taiwan_02.png'),

    // promote
    promote_01: require('../img/promote/promote_01.png'),
    promote_02: require('../img/promote/promote_02.png'),
    promote_03: require('../img/promote/promote_03.png'),
    promote_04: require('../img/promote/promote_04.png'),
    promote_05: require('../img/promote/promote_05.png'),
    upgrade_01: require('../img/promote/upgrade_01.png'),
    upgrade_02: require('../img/promote/upgrade_02.png'),
    upgrade_03: require('../img/promote/upgrade_03.png'),
    upgrade_04: require('../img/promote/upgrade_04.png'),
}

export default Images;