import React, {useState, useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { Metrics, Styles, Images  } from '../Themes'
import OptionTalkingTextIE from "./Option/OptionTalkingTextIE"
import OptionTalkingTextNull from "./Option/OptionTalkingTextNull"
import Next from "../components/Button/Next"
import { useHistory } from "react-router-dom";
import { ITransitionTwoItem } from '../types/index';
import { isIE } from 'react-device-detect';

const Wrapper = styled(Styles.TransitionWrapper())`
  @media (max-width: ${Metrics.tablet}){
    top: 15%;
  }
`
const ImageArea = styled(Styles.TransitionImgArea())`
  height: 50vh;
  margin-top: 60px;
  @media (max-width: ${Metrics.tablet}){
    margin-top: 0;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active){
    height: 50vh;
  }
`
const Bg = styled(Styles.TransitionBg())`
`
const Img = styled(Styles.TransitionImg())`
  @media (max-width: ${Metrics.tablet}){
    max-width: 350px;
    height: 80%;
  }
`
const OptionArea = styled(Styles.TransitionOptionArea())`
  height: 40vh;
  .next{
    align-self: end
  }
  @media (max-width: ${Metrics.tablet}){
    max-height: 200px;
    .next{
      align-self: start
    }
  }
  
`

const TransitionTwoWrapper = ( props: ITransitionTwoItem) => {
  const history = useHistory();
  const [ text, setText ] = useState('')
  const [ text_2, setText_2 ] = useState('')
  
  useEffect(() => {
    setTimeout(() => {
      setText(`${props?.text}`)
      setText_2(`${props?.text_2}`)
    ;}, 1000)
  })
  if(isIE){
    const OptionTalkingText = lazy(() => import("./Option/OptionTalkingTextIE"));
    return (
      <Wrapper>
        <ImageArea>
          <Bg src={`${Images.bg}`} className='animate__flash animate__animated animate__slow animate__delay-1s' />
          <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s animate__faster' />
        </ImageArea>
        <OptionArea>
          <Suspense fallback={<OptionTalkingTextNull
            portrait={props?.portrait}
            text={text}
            portrait_2={props?.portrait_2}
            text_2={text_2}
          />}>
            <OptionTalkingText
              portrait={props?.portrait}
              text={text}
              portrait_2={props?.portrait_2}
              text_2={text_2}
            />
          </Suspense>
          <div onClick={() => history.location.state 
              ? history.push({ pathname: `${props.href}`, state: history.location.state }) 
              : history.push({ pathname: `${props.href}`})}
            className='next'
          >
            <Next />
          </div>
        </OptionArea>
      </Wrapper>
    )
  }else{
    const OptionTalkingText = lazy(() => import("./Option/OptionTalkingText"));
    return (
      <Wrapper>
        <ImageArea>
          <Bg src={`${Images.bg}`} className='animate__flash animate__animated animate__slow animate__delay-1s' />
          <Img src={props.img} className='animate__fadeIn animate__animated animate__delay-1s animate__faster' />
        </ImageArea>
        <OptionArea>
          <Suspense fallback={<OptionTalkingTextNull
            portrait={props?.portrait}
            text={text}
            portrait_2={props?.portrait_2}
            text_2={text_2}
          />}>
            <OptionTalkingText
              portrait={props?.portrait}
              text={text}
              portrait_2={props?.portrait_2}
              text_2={text_2}
            />
          </Suspense>
          <div onClick={() => history.location.state 
              ? history.push({ pathname: `${props.href}`, state: history.location.state }) 
              : history.push({ pathname: `${props.href}`})}
            className='next'
          >
            <Next />
          </div>
        </OptionArea>
      </Wrapper>
    )
  }
  
}

export default TransitionTwoWrapper
